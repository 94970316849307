import styled, { css } from 'styled-components';
import FeelkyBannerThirdHumanImg from '../../../../../../assets/img/feelky-bnnaer-third-human.png';
import PixelLoader from '../PixelLoader/Pixel';
import FeelkyTimer from '../../../../../../assets/img/feelky-timer.png';

const Timer = () => {
  return (
    <StyledTimerWrapper>
      <StyledLoaderWrapper>
        <StyledTopLoaderImgWrapper $url={FeelkyBannerThirdHumanImg} />
        <StyledTopLoaderChatWrapper>
          <StyledChatingBox $option="default">안녕하세요!</StyledChatingBox>
          <StyledChatingBox $option="default">
            현재 5명 정도 모여있습니다.
          </StyledChatingBox>
          <StyledChatingBox $option="default" style={{ width: 160 }} />
        </StyledTopLoaderChatWrapper>
        <StyledTopLoader>
          <PixelLoader width={95} height={10} pixelSize={5} bottom />
        </StyledTopLoader>
      </StyledLoaderWrapper>
      <StyledTimeLoaderWrapper>
        <img src={FeelkyTimer} alt="clock" />
      </StyledTimeLoaderWrapper>
      <StyledLoaderWrapper>
        <StyledBottomLoaderImgWrapper $url={FeelkyBannerThirdHumanImg} />
        <StyledBottomLoaderChatWrapper>
          <StyledChatingBox $option="other" style={{ width: 150 }} />
          <StyledChatingBox $option="other">
            요청 받아주셔서 감사해요ㅎㅎ
          </StyledChatingBox>
          <StyledChatingBox $option="other">
            언제까지 진행하는건가요?
          </StyledChatingBox>
        </StyledBottomLoaderChatWrapper>
        <StyledBottomLoader>
          <PixelLoader width={95} height={10} pixelSize={5} top />
        </StyledBottomLoader>
      </StyledLoaderWrapper>
    </StyledTimerWrapper>
  );
};

export default Timer;

const chatOption = {
  default: css`
    background: #353535;
    border-top-left-radius: 0;
    color: #ffffff;
  `,
  other: css`
    background: #ffffff;
    border-bottom-right-radius: 0;
    color: #353535;
  `,
};

const StyledTimerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  overflow: hidden;
  position: relative;
`;

const StyledLoaderImgWrapper = styled.div`
  width: 45%;

  background-image: url(${({ $url }) => $url});
  background-size: cover;
`;

const StyledTopLoaderImgWrapper = styled(StyledLoaderImgWrapper)``;

const StyledBottomLoaderImgWrapper = styled(StyledLoaderImgWrapper)`
  background-position: bottom;
`;

const StyledLoaderChatWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: end;
  gap: 10px;
`;

const StyledTopLoaderChatWrapper = styled(StyledLoaderChatWrapper)``;

const StyledBottomLoaderChatWrapper = styled(StyledLoaderChatWrapper)`
  align-items: end;
  justify-content: start;
`;

const StyledTopLoader = styled.div`
  width: 100%;
  height: 50px;

  position: absolute;
  bottom: 0;
  left: 0;
`;

const StyledBottomLoader = styled.div`
  width: 100%;
  height: 50px;

  position: absolute;
  top: 0;
  left: 0;
`;

const StyledTimeLoaderWrapper = styled.div`
  width: 100%;
  flex: 0 0 134px;
  background: #1a1a1a;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;

  img {
    width: 80%;
  }

  @media (max-width: 800px) {
    flex: 0 0 114px;
  }

  @media (max-width: 800px) {
    flex: 0 0 96px;
  }

  @media (max-width: 400px) {
    flex: 0 0 78px;
  }
`;

const StyledChatingBox = styled.div`
  height: 24%;

  display: flex;
  align-items: center;
  padding: 0 16px;

  font-size: 16px;
  font-weight: 400;

  border-radius: 8px;

  ${({ $option }) => chatOption[$option]}

  @media (max-width: 1600px) {
    font-size: 14px;
    padding: 0 14px;
  }

  @media (max-width: 800px) {
    font-size: 12px;
    padding: 0 12px;
  }
  @media (max-width: 400px) {
    font-size: 8px;
    padding: 0 8px;
  }
`;
