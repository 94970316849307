/* eslint-disable react/no-array-index-key */
import styled, { keyframes } from 'styled-components';
import Category from '../Category/Category';

const NewCategory = ({ categorys }) => {
  return (
    <div>
      <StyledCategoryWrapper>
        {categorys.map(({ item }, i) => (
          <StyledCategoryItemWrapper key={i}>
            {item.map(({ icon, text, option }, index) => (
              <StyledCategoryAnimation
                key={index}
                $delay={`${Math.random() * 2}s`}
              >
                <Category icon={icon} text={text} option={option} />
              </StyledCategoryAnimation>
            ))}
          </StyledCategoryItemWrapper>
        ))}
      </StyledCategoryWrapper>
    </div>
  );
};
export default NewCategory;

const updown = keyframes`
    0% { transform: translate(0); }
    12.5% { transform: translate(0, 7%); }
    25% { transform: translate(0);  }
    37.5% { transform: translate(0, 7%); }
    50% { transform: translate(0); }
    62.5% { transform: translate(0, 7%); }
    75% { transform: translate(0); }
    87.5% { transform: translate(0, 7%); }
    100% { transform: translate(0); }
`;

const StyledCategoryWrapper = styled.div`
  width: 475px;
  height: 248px;
  padding: 10px 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const StyledCategoryItemWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;

  position: relative;
`;

const StyledCategoryAnimation = styled.div`
  animation: ${updown} 10s infinite ease-in-out;

  animation-delay: ${({ $delay }) => $delay};
`;
