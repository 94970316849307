import axios from 'axios';

// import rankerData from './RankerData';
import bannerData from './BannerData';

const API = axios.create({
    // baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
});

API.interceptors.request.use(
    function (config) {
        switch(config.serverType) {
            case '990001':
                config.baseURL = process.env["REACT_APP_API_PROD"];
                break;
            case '990002':
                config.baseURL = process.env["REACT_APP_API_STG"];
                break;
            case '990003':
                config.baseURL = process.env["REACT_APP_API_DEV"];
                break;
            default:
                config.baseURL = process.env["REACT_APP_SERVER_URL"];
        }

        return config;
    },
    function (error) {
        // 요청 에러 직전 호출됩니다.
        return Promise.reject(error);
    }
);

// 랭커 리스트를 가져오는 함수
export const getRankerList = async serverType => {
    try {
        const response = await API.get('/event/ranks', {serverType});
        return response.data.result;
    } catch (error) {
        console.error('Error fetching ranker list:', error);
        throw error;
    }
};


export const getEventBanner = async () => {
    try {
        return bannerData;
    } catch (error) {
        console.error('Error fetching ranker list:', error);
        throw error;
    }
}
