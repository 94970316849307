import { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import * as EventBanner from "./EventBannerView.styles";

import * as api from "apis/RankApi";

const EventBannerView = () => {
    const [banner, setBanner] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    let settings = {
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        className: 'slider-wrapper',
        pauseOnHover: false,                    // 배너 클릭 시 자동 이동 멈춤
    };

    const pageInit = async () => {
        let list = await api.getEventBanner();
        setBanner(list);

        return true;
    }

    const changeBanner = (prev, next) => {
        setCurrentPage(next + 1);
    }

    const onClickBanner = (item, idx) => {
        console.log('# onClickBanner:', idx, item);
        if(isAndroid) {
            window.location.href = 'intent://instagram.com/feelky_official/#Intent;package=com.instagram.android;scheme=https;end';
        } else if (isIOS) {
            // let visitedAt = new Date().getTime();
            // setTimeout(() => {
            //     if(new Date().getTime() - visitedAt < 2000) {
            //         // window.location.href = 'https://apps.apple.com/kr/app/instagram/id389801252';
            //         window.location.href = 'itms-apps://itunes.apple.com/app/id389801252';
            //     }
            // }, 1500);
            //
            // setTimeout(() => {
            //     window.location.href = 'instagram://user?username=feelky_official';
            // }, 0)

            window.location.href = 'https://www.instagram.com/feelky_official/';
        } else {

        }
    }

    useEffect(() => {
        pageInit().then(r => {
            console.log(`page init complete {${r}}`)
        });
    }, []);

    return <EventBanner.Wrapper>
        <EventBanner.Pagination>{currentPage}/{banner.length}</EventBanner.Pagination>
        <Slider {...settings} beforeChange={(prev, next) => changeBanner(prev, next)}>
            {
                banner.length && banner.map((item, idx) => {
                    return <EventBanner.BannerImage key={item.uuid} src={item.bannerUrl} onClick={() => onClickBanner(item, idx)}/>
                })
            }
        </Slider>
    </EventBanner.Wrapper>
}

export default EventBannerView;