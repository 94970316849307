import styled from "styled-components";

let thumbnailSize = '46px';

const MainVideoBoxWrapper = styled.div`
    width: 100%;
    display: flex;
    
    padding: 0 16px;
    
    z-index: 1000;
    
    > .thumbnail {
        width: ${thumbnailSize};
        aspect-ratio: 1/1;
        border-radius: 50%;
    }
    
    > .info {
        max-width: calc(100% - ${thumbnailSize});
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;
        
        color: #ffffff;
        font-size: 16px;
        font-weight: 250;
        
        > .title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        
        > .nickname {
            margin-top: 4px;
            font-size: 12px;
            color: #9D9D9D;
        }
    }
`;

export { MainVideoBoxWrapper };