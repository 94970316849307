import {popupOpen} from "store/layerPopupSlice";
import {CommentBasicInfo, CommentBasicWrapper, Description} from "./CommentBasic.styles";

import iconUploadStroke from "assets/icon/share_video_comment_upload_stroke.svg";
import {useDispatch} from "react-redux";

const CommentBasic = props => {
    const dispatch = useDispatch();

    return <CommentBasicWrapper>
        <CommentBasicInfo thumbnail={props.thumbnail} onClick={() => dispatch(popupOpen('MoveStore'))}>
            <Description highlight={'실시간 영상'}>에는?</Description>
            <Description highlight={'실시간 영상댓글'}>이지!</Description>
            <img src={iconUploadStroke} alt={'upload-video'}/>
            <Description>실시간 영상댓글로</Description>
            <Description>인연을 이어가세요!</Description>
        </CommentBasicInfo>
    </CommentBasicWrapper>
};

export default CommentBasic;