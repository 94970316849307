import { configureStore } from "@reduxjs/toolkit";
import layerPopupReducer from "./layerPopupSlice";


const store = configureStore({
    reducer: {
        layerPopup: layerPopupReducer
    }
});


export default store;