import { Route } from 'react-router-dom';
import Landing from '../pages/Landing/Landing';
import Share from "../pages/App/Share/Share";

const publicRoutes = [
    {
        path: '/',
        element: <Landing />,
    },
    {
        path: '/app/share/*',
        element: <Share />,
    }
];

const createPublicRoutes = () => {
    return publicRoutes.map((route) => {
        return (
            <Route key={route.path} path={route.path} element={route.element} />
        );
    });
};

export default createPublicRoutes;
