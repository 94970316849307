import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";
import styled from "styled-components";

import CommentHeader from "components/App/Share/Video/Comment/CommentHeader";
import CommentContents from "components/App/Share/Video/Comment/CommentContents";

import { getComments } from "apis/VideoApi";
import CommentBasic from "components/App/Share/Video/Comment/CommentBasic";

const CommentVideoShareWrapper = styled.div`
    width: 100vw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    background: #000000;
`;

const VideoCommentShare = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [commentsInfo, setCommentsInfo] = useState({});
    const [messagePopup, setMessagePopup] = useState({ show:false, message:'' });
    const [galleryShow, setGalleryShow] = useState(false);

    const getShareComments = async () => {
        return await getComments(searchParams.get('st'), searchParams.get('id'))
    }

    const drawCommentList = () => {
        if(commentsInfo.list?.length === 0) return <CommentBasic { ...commentsInfo.mainVideo }/>
        else if(0 < commentsInfo.list?.length) return <CommentContents { ...commentsInfo } galleryShow={galleryShow} setGalleryShow={setGalleryShow}/>
    }

    useEffect(() => {
        getShareComments().then(res => {
            setCommentsInfo(res);
        });
    }, []);

    return <CommentVideoShareWrapper onClick={(e) => {
        if(messagePopup.show) setMessagePopup({ show:false, message:'' });
    }}>
        <CommentHeader { ...commentsInfo.mainVideo }
            messagePopup={messagePopup} setMessagePopup={setMessagePopup}
            galleryShow={galleryShow} setGalleryShow={setGalleryShow}/>
        {drawCommentList()}
    </CommentVideoShareWrapper>
}

export default VideoCommentShare;