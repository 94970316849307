import styled from "styled-components";

const VideoMiddleContainer = styled.div`
    width: 100%;
    flex: 1 1 auto;
    
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 10px;
`;

const VideoMiddleIconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    > .icon-wrap {
        margin-top: 16px;
        
        > .icon {
            padding: 8px;

            > img {
                display: block;
            }
        }
        
        > .count {
            color: #ffffff;
            font-size: 12px;
            font-weight: 300;
            text-align: center;
        }
    }
`

export { VideoMiddleContainer, VideoMiddleIconWrapper };