import styled, { css } from 'styled-components';

const Category = ({ icon, text, option = 'default' }) => {
  return (
    <StyledCategoryWrapper $option={option}>
      <StyledCategoryTitle>
        <StyledCategoryIcon>{icon}</StyledCategoryIcon>
        {text}
      </StyledCategoryTitle>
    </StyledCategoryWrapper>
  );
};

export default Category;

const categoryOptions = {
  default: css`
    background: #7b61ff93;
    box-shadow: 4px 4px 4px 0px #7b61ff26;

    padding: 10px 20px;
    color: #ffffff;
  `,
  glass: css`
    border: 1.4px solid #7d7ae0;
    color: #7d7ae0;
  `,
};

const StyledCategoryWrapper = styled.div`
  height: 48px;

  padding: 10px 12px;
  border-radius: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $option }) => categoryOptions[$option]}

  @media (max-width: 1600px) {
    height: 42px;
  }
  @media (max-width: 800px) {
    height: 36px;
  }
  @media (max-width: 400px) {
    height: 30px;
  }
`;

export const StyledCategoryIcon = styled.div`
  font-size: 26px;

  @media (max-width: 16000px) {
    font-size: 24px;
  }
  @media (max-width: 800px) {
    font-size: 21px;
  }
  @media (max-width: 400px) {
    font-size: 18px;
  }
`;

export const StyledCategoryTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  font-family: Pretendard;
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 16000px) {
    gap: 10px;
    font-size: 16px;
  }
  @media (max-width: 800px) {
    gap: 7px;
    font-size: 14px;
  }
  @media (max-width: 400px) {
    gap: 4px;
    font-size: 12px;
  }
`;
