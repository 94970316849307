import 'moment/locale/ko';
import styled from "styled-components";
import {useEffect} from "react";

const VideoEndContainer = styled.div`
    width: 100vw;
    height: 100%;
    position: fixed;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    color: #ffffff;
    font-weight: 300;
    z-index: 2000;

    &:before {
        content: '';
        width: 100vw;
        height: 100%;
        position: absolute;

        background: #000000;
        opacity: 0.8;
    }
    
    > .end-time {
        font-size: 46px;
        z-index: 2001;
    }
    
    > .description {
        font-size: 22px;
        z-index: 2001;
        
        &:nth-child(2) {
            margin-top: 30px;
        }
    }
`;

const VideoEnd = props => {

    return <VideoEndContainer>
        <div className={'end-time'}>00:00:00</div>
        <div className={'description'}>아쉽지만, 해당 영상의</div>
        <div className={'description'}>게시 시간이 끝나버렸어요!</div>
    </VideoEndContainer>
}

export default VideoEnd;