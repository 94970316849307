import styled from 'styled-components';

const BannerWrapper = ({ children, background, mobileBackground }) => {
  return (
    <StyledBannerWrapper
      $background={background}
      $mobileBackground={mobileBackground}
    >
      {children}
    </StyledBannerWrapper>
  );
};

export default BannerWrapper;

const StyledBannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  padding-top: 84px;

  z-index: 1000;

  background: ${({ $background }) => $background || ''};

  @media (max-width: 800px) {
    padding-top: 20%;
    background: ${({ $mobileBackground }) => $mobileBackground};
  }
`;
