import styled from 'styled-components';
import BannerWrapper from '../../../../components/common/BannerWrapper/BannerWrapper';
import Timer from './components/Timer/Timer';
import Title from '../../../../components/common/Title/Title';

const BannerThird = () => {
  return (
    <BannerWrapper background="#1A1A1A">
      <StyledBannerThirdWrapper>
        <StyledBannerThirdTitleWrapper>
          <Title
            effectTitle="매일 매일 다시 태어나고 싶은 당신에게"
            mainTitle={
              <>
                <div>7시간의 기적, 7시간의 행운</div>
                <div>
                  <span>쫄깃한 7시간 타임어택</span>
                </div>
              </>
            }
            subTitle={
              <>
                모든 영상에게 주어진 시간은 딱 7시간 7분 7초!
                <br />
                이 시간이 지나면 모든 영상, 그리고 채팅방까지 사라진다...
                <br />
                영상이 사라지기전 기적과 행운을 만들기 위해 할 수 있는거
                <br />
                망설이지 말고 다 해보자! Just Feel it!
              </>
            }
            mobileSubTitle={
              <>
                모든 영상에게 주어진 시간은 딱 7시간 7분 7초!
                <br />
                이 시간이 지나면 모든 영상, 그리고 채팅방까지 사라진다...
                <br />
              </>
            }
          />
        </StyledBannerThirdTitleWrapper>
        <StyledBannerThirdTimerWrapper>
          <Timer />
        </StyledBannerThirdTimerWrapper>
      </StyledBannerThirdWrapper>
    </BannerWrapper>
  );
};

export default BannerThird;

const StyledBannerThirdWrapper = styled.div`
  width: 1090px;
  height: 100%;

  margin: 0 auto;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1090px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: stretch;
    gap: 40px;
  }
`;
const StyledBannerThirdTitleWrapper = styled.div``;

const StyledBannerThirdTimerWrapper = styled.div`
  width: 470px;
  aspect-ratio: 1 / 1;
  padding: 0 20px;

  @media (max-width: 1600px) {
    width: 430px;
  }

  @media (max-width: 900px) {
    width: 390px;
  }

  @media (max-width: 800px) {
    width: 380px;
  }
  @media (max-width: 400px) {
    width: 100%;
    width: auto;
    height: 40vh;
  }
`;
