import styled, { keyframes } from "styled-components";

const popupSlide = keyframes`
    from {
        margin-bottom: -240px;
    }
    
    to {
        margin-bottom: 0;
    }
`;

const BottomLayerPopupWrapper = styled.div`
    width: 100vw;
    height: 100dvh;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;

    background: RGBA(0, 0, 0, 0.7);
    z-index: 2000;

    > .layer-popup {
        max-width: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 0 24px 20px 24px;

        border-radius: 24px 24px 0 0;
        background: #262626;

        color: #ffffff;

        animation: ${popupSlide} .4s 1;
        animation-timing-function: ease-in-out;

        > div {
            width: 100%;
        }

        > .title {
            font-size: 20px;
        }

        > .description {
            margin-top: 16px;
            color: #c4c4c4;
            font-size: 14px;
            font-weight: 200;
        }

        > .button-wrap {
            margin-top: 28px;

            > button {
                width: 100%;
                padding: 14px 0;
                border-radius: 24px;
                background: #7d7ae0;
                color: #ffffff;
                font-size: 16px;
                font-weight: 300;
            }
        }

        &:before {
            content: '';
            width: 26%;
            height: 6px;
            margin-top: 16px;
            margin-bottom: 30px;
            border-radius: 16px;
            background: #434343;
        }
    }
`;



export { BottomLayerPopupWrapper };