import checkAosIos from './checkAosIos';
import downloadAppAndroid from './downloadAppAndroid';
import downloadAppIos from './downloadAppIos';

const downloadApp = () => {
  const userAgent = checkAosIos();

  if (userAgent === 'iOS') downloadAppIos();
  else downloadAppAndroid();
};

export default downloadApp;
