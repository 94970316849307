import styled from "styled-components";


export const Wrapper = styled.div`
    width: 100vw;
    position: relative;
`;

export const Pagination = styled.span`
    width: 10%;
    position: absolute;
    right: 20px;
    bottom: 8px;
    padding: 4px 0;
    border-radius: 99px;
    background: #000000;
    opacity: .5;
    color: #ffffff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    z-index: 1000;
`;

export const BannerImage = styled.img`
    width: 100vw;
    aspect-ratio: 4;
    vertical-align: top;
`;