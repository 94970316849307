import { useEffect, useState } from "react";
import VideoMiddle from "./VideoMiddle";
import VideoBottom from "./VideoBottom";
import VideoEnd from "./VideoEnd";

import { VideoLayoutWrapper } from "./VideoLayout.styles";
import VideoDelete from "./VideoDelete";

const VideoLayout = videoInfo => {
    const [showVideoEnd, setShowVideoEnd] = useState(false);
    const [showVideoDelete, setShowVideoDelete] = useState(false);

    useEffect(() => {
        if(videoInfo?.status !== '460100') {
            if(videoInfo?.status === '460101') {
                setShowVideoEnd(true);
            } else {
                setShowVideoDelete(true);
            }

            setTimeout(() => {
                if(videoInfo.nextVideo) videoInfo.nextVideo(1);
            }, 2000)
        }
    }, [videoInfo]);

    return <VideoLayoutWrapper>
       <VideoMiddle { ...videoInfo }/>
       <VideoBottom { ...videoInfo }/>
       { showVideoEnd && <VideoEnd/> }
       { showVideoDelete && <VideoDelete/> }
   </VideoLayoutWrapper>
}

export default VideoLayout;