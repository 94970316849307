import styled from 'styled-components';

import rankStar from 'assets/icon/event-ranker-star.svg';

const RankerPageWrapper = styled.div`
    width: 100vw;
    padding: 28px 20px;
    background: #000000;
`;

const RankerListWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const RankerItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    color: #ffffff;
    
    > .ranking {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(${rankStar}) no-repeat center center;
        background-size: contain;
    }
    
    > .profile {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        padding-left: 12px;
        
        > img {
            width: 56px;
            height: 56px;
            border-radius: 50%;
        }
        
        > span {
            padding-left: 12px;
            font-size: 14px;
            font-weight: 300;
        }
    }
    
    > .score {
        font-size: 14px;
        font-weight: 200;
    }
`;


export {
    RankerPageWrapper,
    RankerListWrapper,
    RankerItem
};