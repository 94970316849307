import { useEffect, useState } from "react";

import EventTopView from "components/App/Event/EventTopView";
import EventBannerView from "components/App/Event/EventBannerView";
import HeaderRankingSystemView from "components/App/Event/Rank/HeaderRankingSystemView";
import MedalTopRankerListView from "components/App/Event/Rank/MedalTopRankerListView";
import ExtendedRankerListView from "components/App/Event/Rank/ExtendedRankerListView";
import WelcomePopup from 'components/App/Event/Rank/WelcomePopup';

import * as api from "apis/RankApi";
import styled from "styled-components";
import {useSearchParams} from "react-router-dom";


const RankContainer = styled.div`
    width: 100vw;
    height: 100dvh;
    background: #000000;
    overflow-y: auto;
`;

const RankSystemContainer = () => {
    const [searchParams] = useSearchParams();
    const [eventTimeRemaining, setEventTimeRemaining] = useState('');
    const [rankerList, setRankerList] = useState([]);

    useEffect(() => {
        handleRefresh().then(r => {
            // console.log('# RankSystemContainer:', r)
        });
    }, []);

    const handleRefresh = async () => {
        let result = await api.getRankerList(searchParams.get('st'));
        setEventTimeRemaining(result.eventEndDateTime);
        setRankerList(result.topRankerDto);

        return 'ok';
    };

    return (
        <RankContainer>
            <WelcomePopup/>
            <EventTopView title={'랭킹'}/>
            <EventBannerView/>
            <HeaderRankingSystemView
                handleRefresh={handleRefresh}
                eventTimeRemaining={eventTimeRemaining}
            />
            { rankerList.length && <MedalTopRankerListView list={rankerList} /> }
            { rankerList.length && <ExtendedRankerListView list={rankerList.slice(3)} /> }
        </RankContainer>
    );
};

export default RankSystemContainer;
