import * as S from './Topbar.styles';

import LogoIcon from '../../assets/img/feelky_full_icon.svg';

import downloadApp from '../../utils/downloadApp';

const TopBar = () => {
  return (
    <S.StyledTopBarWrapper>
      <S.StyledTopBarLayout>
        <S.StyledTopBarLogoWrapper>
          <S.StyledTopBarLogoIcon src={LogoIcon} alt="Logo Icon" />
        </S.StyledTopBarLogoWrapper>
        <S.StyledTopBarAppInstallButton onClick={downloadApp}>
          앱 다운로드
        </S.StyledTopBarAppInstallButton>
      </S.StyledTopBarLayout>
    </S.StyledTopBarWrapper>
  );
};

export default TopBar;
