import 'moment/locale/ko';
import styled from "styled-components";

import iconDelete from 'assets/icon/share_video_delete.svg';

const VideoEndContainer = styled.div`
    width: 100vw;
    height: 100%;
    position: fixed;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    color: #ffffff;
    font-weight: 300;
    z-index: 2000;
    
    &:before {
        content: '';
        width: 100vw;
        height: 100%;
        position: absolute;
        
        background: #000000;
        opacity: 0.8;
    }
    
    > img {
        z-index: 2001;
    }
    
    > .description {
        font-size: 22px;
        z-index: 2001;
        
        &:nth-child(2) {
            margin-top: 20px;
        }
    }
`;

const VideoEnd = props => {

    return <VideoEndContainer>
        <img src={iconDelete} alt="icon"/>
        <div className={'description'}>아쉽지만, 게시자의 의해</div>
        <div className={'description'}>영상이 삭제되었어요.</div>
    </VideoEndContainer>
}

export default VideoEnd;