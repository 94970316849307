import {styled, css} from "styled-components";
import eventCrown from "assets/icon/event-crown.svg";
const WelcomePopupContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: ${(props) => { return props.$flag ? 'flex':'none' }};
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.8);
    
    color: #ffffff;
    z-index: 1001;
    
    > img {
        width: 64%;
        margin-top: 24px;
    }
`;

const PageTitle = styled.div`
    color: #ffffff;
    font-family: 'Gmarket Sans', serif;
    font-size: 22px;
    font-weight: 400;
`;

const PageSubTitle = styled.div`
    margin-top: 8px;
    
    background: linear-gradient(to right, #BCFFE0, #C6C4FF);
    background-clip: text;
    
    color: #ffffff;
    font-family: 'Gmarket Sans', serif;
    font-size: 38px;
    font-weight: 500;
    -webkit-text-fill-color: transparent;
    text-align: center;
`;


export {
    WelcomePopupContainer,
    PageTitle,
    PageSubTitle,
}