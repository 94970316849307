/* eslint-disable react/no-array-index-key */
import styled from 'styled-components';
import { ReactComponent as LogoIcon } from '../../../../assets/img/feelky_full_icon.svg';
import GooglePlayIcon from '../../../../assets/img/googlePlay-icon.png';
import AppleIcon from '../../../../assets/img/apple_icon.svg';
import { ReactComponent as Instagrame } from '../../../../assets/img/instagram_icon.svg';
import { ReactComponent as Youtube } from '../../../../assets/img/youtube_icon.svg';
import feelkyInstagramImageOne from '../../../../assets/img/feelky-banner-last-instagram-1.png';
import feelkyInstagramImageTwo from '../../../../assets/img/feelky-banner-last-instagram-2.png';
import feelkyInstagramImageThree from '../../../../assets/img/feelky-banner-last-instagram-3.png';
import downloadAppAndroid from '../../../../utils/downloadAppAndroid';
import downloadAppIos from '../../../../utils/downloadAppIos';

const DownLoadFromGoogle = () => {
  return (
    <StyledBannerLastDownloadButton onClick={downloadAppAndroid}>
      <StyledBannerLastDownloadButtonIcon
        src={GooglePlayIcon}
        alt="Google Play Icon"
      />
      Google Play
    </StyledBannerLastDownloadButton>
  );
};
const DownLoadFromApp = () => {
  return (
    <StyledBannerLastDownloadButton onClick={downloadAppIos}>
      <StyledBannerLastDownloadButtonIcon
        src={AppleIcon}
        alt="App Store Icon"
      />
      App Store
    </StyledBannerLastDownloadButton>
  );
};

const BannerLast = () => {
  const PolicyList = [
    '이용약관',
    '개인정보처리방침',
    '위치기반서비스 이용약관',
    '청소년보호정책',
  ];

  const InstagramUrl = 'https://www.instagram.com/feelky_official';
  const YoutubeUrl = 'https://www.youtube.com/@feelky_official';

  const SnsList = [
    {
      Icon: Instagrame,
      url: InstagramUrl,
    },
    {
      Icon: Youtube,
      url: YoutubeUrl,
    },
  ];

  const InstagramList = [
    feelkyInstagramImageOne,
    feelkyInstagramImageTwo,
    feelkyInstagramImageThree,
  ];

  const handleClickSns = (url) => {
    window.location = url;
  };

  return (
    <StyledBannerLastWrapper>
      <StyledBannerLastWebWrapper>
        <StyledBannerLastLogoWrapper>
          <StyledBannerLastTopBarLogoIcon>
            <LogoIcon />
          </StyledBannerLastTopBarLogoIcon>
        </StyledBannerLastLogoWrapper>
        <StyledBannerLastTitleWrapper>
          <StyledBannerLastTitle>
            <div>
              <p>상호명 : 주식회사 필키컴퍼니</p>
              <p>대표이사 : 전현정</p>
              <p>개인정보책임관리자 : 전현정</p>
            </div>
            <p>주소 : 서울특별시 강남구 학동로 165, 2층 208-3-30호</p>
            <p>사업자등록번호 : 433-81-03088</p>
          </StyledBannerLastTitle>
          <StyledBannerLastDownloadWrapper>
            <StyledBannerLastDownloadTitle>
              필키 앱 다운로드
            </StyledBannerLastDownloadTitle>
            <StyledBannerLastDownloadButtonWrapper>
              <DownLoadFromGoogle />
              <DownLoadFromApp />
            </StyledBannerLastDownloadButtonWrapper>
          </StyledBannerLastDownloadWrapper>
        </StyledBannerLastTitleWrapper>
        <StyledBannderLastBottomBar />
        <StyledBannerLastPolicyWrapper>
          <StyledBannerLastPolicyTitleWrapper>
            {PolicyList.map((value) => (
              <StyledBannerLastPolicyTitle key={value}>
                {value}
              </StyledBannerLastPolicyTitle>
            ))}
          </StyledBannerLastPolicyTitleWrapper>
          <StyledBannerLastPolicySnsWrapper>
            {SnsList.map(({ Icon, url }) => (
              <StyledBannerLastPolicySnsIcon
                onClick={() => {
                  handleClickSns(url);
                }}
                key={url}
              >
                <Icon />
              </StyledBannerLastPolicySnsIcon>
            ))}
          </StyledBannerLastPolicySnsWrapper>
        </StyledBannerLastPolicyWrapper>
      </StyledBannerLastWebWrapper>
      <StyledBannerLastMobileWrapper>
        <StyledBannerLastInstagramWrapper>
          <StyledBannerLastInstagramTitleWrapper>
            <Instagrame />
            <div>feelky_official</div>
          </StyledBannerLastInstagramTitleWrapper>
          <StyledBannerLastInstagramBannersWrapper>
            {InstagramList.map((url) => (
              <StyledBannerLastInstagramImgWrapper
                key={url}
                onClick={() => handleClickSns(InstagramUrl)}
              >
                <img src={url} alt={url} />
              </StyledBannerLastInstagramImgWrapper>
            ))}
          </StyledBannerLastInstagramBannersWrapper>
        </StyledBannerLastInstagramWrapper>
        <StyledBannerLastMobileAppDownload>
          <StyledBannerLastDownloadTitle>
            필키 앱 다운로드
          </StyledBannerLastDownloadTitle>
          <StyledBannerLastDownloadButtonWrapper>
            <DownLoadFromGoogle />
            <DownLoadFromApp />
          </StyledBannerLastDownloadButtonWrapper>
        </StyledBannerLastMobileAppDownload>
        <StyledBannerLastMobileTitleWrapper>
          <StyledBannerLastDownloadTitle>
            주식회사 필키컴퍼니
          </StyledBannerLastDownloadTitle>
          <StyledBannerLastTitle>
            <div>
              <p>대표이사 : 전현정</p>
              <p> 개인정보책임관리자 : 전현정</p>
            </div>
            <p>주소 : 서울특별시 강남구 학동로 165, 2층 208-3-30호</p>
            <p>
              사업자등록번호 : 433-81-03088
              <StyledBannerLastMobileLinkBtn>
                사업자정보 확인
              </StyledBannerLastMobileLinkBtn>
            </p>
          </StyledBannerLastTitle>
        </StyledBannerLastMobileTitleWrapper>
        <StyledBannerLastPolicyWrapper>
          <StyledBannerLastPolicyTitleWrapper>
            {PolicyList.map((value) => (
              <StyledBannerLastPolicyTitle key={value}>
                {value}
              </StyledBannerLastPolicyTitle>
            ))}
          </StyledBannerLastPolicyTitleWrapper>
        </StyledBannerLastPolicyWrapper>
        <StyledBannderLastBottomBar />

        <StyledBannerLastPolicySnsWrapper>
          {SnsList.map(({ Icon, url }) => (
            <StyledBannerLastPolicySnsIcon
              onClick={() => {
                handleClickSns(url);
              }}
              key={url}
            >
              <Icon />
            </StyledBannerLastPolicySnsIcon>
          ))}
        </StyledBannerLastPolicySnsWrapper>
      </StyledBannerLastMobileWrapper>
    </StyledBannerLastWrapper>
  );
};

export default BannerLast;

const StyledBannerLastWrapper = styled.div`
  max-width: 1090px;
  width: 100%;
  height: 290px;

  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 800px) {
    background: #1a1a1a;

    height: 100%;
    padding-top: 45px;
    padding-bottom: 40px;
  }
`;

const StyledBannerLastWebWrapper = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledBannerLastLogoWrapper = styled.div`
  margin-top: 70px;
`;

const StyledBannerLastTopBarLogoIcon = styled.div`
  cursor: pointer;

  svg {
    > path:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(
        :nth-child(4)
      ) {
      fill: #80808084;
    }
  }
`;

const StyledBannerLastTitleWrapper = styled.div`
  margin-top: 39.93px;
  display: flex;
  justify-content: space-between;
`;

const StyledBannerLastTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 100;

  color: #ffffff;

  > div {
    display: flex;
    gap: 20px;
  }

  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

const StyledBannerLastDownloadWrapper = styled.div``;

const StyledBannerLastDownloadTitle = styled.p`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
`;

const StyledBannerLastDownloadButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
`;

const StyledBannerLastDownloadButton = styled.div`
  width: 131px;
  height: 36px;

  border-radius: 4px;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 200;

  background: #434343;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  cursor: pointer;

  transition: 0.5s;

  &:hover {
    background: rgb(80, 80, 80);
  }
`;

const StyledBannerLastDownloadButtonIcon = styled.img`
  width: 20px;
`;

const StyledBannderLastBottomBar = styled.div`
  width: 100%;
  height: 1px;
  background: #555555;
  margin-top: 30px;
`;

const StyledBannerLastPolicyWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledBannerLastPolicyTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
`;

const StyledBannerLastPolicyTitle = styled.p`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 100;
  color: #ffffff;
  cursor: pointer;
`;

const StyledBannerLastPolicySnsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  height: 38px;

  @media (max-width: 800px) {
    margin-top: 14px;
  }
`;

const StyledBannerLastPolicySnsIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  svg {
    g {
      transition: 0.5s;
    }

    &:hover {
      g {
        opacity: 1;
      }
    }
  }
`;

const StyledBannerLastMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    display: none;
  }
`;

const StyledBannerLastMobileAppDownload = styled.div`
  margin-top: 40px;
`;

const StyledBannerLastMobileTitleWrapper = styled.div`
  margin-top: 30px;
`;

const StyledBannerLastMobileLinkBtn = styled.span`
  color: #7d7ae0;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #7d7ae0;
  }
`;

const StyledBannerLastInstagramWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
`;

const StyledBannerLastInstagramTitleWrapper = styled.div`
  display: flex;
  height: 16px;
  align-items: center;

  font-family: Pretendard;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;

  > svg {
    cursor: pointer;
    height: 100%;
    g {
      opacity: 1;
    }
    path {
      stroke-width: 3;
    }
  }
  > div {
    cursor: pointer;
  }
`;

const StyledBannerLastInstagramBannersWrapper = styled.div`
  width: 100%;
  aspect-ratio: 3 / 1;
  margin-top: 15px;

  display: flex;
  gap: 6px;
`;

const StyledBannerLastInstagramImgWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 4px;

  img {
    transition: 0.5s;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  :hover {
    opacity: 0.8;
  }
`;
