import styled from "styled-components";


const CommentPlayerWrapper = styled.div`
    width: 100vw;
    height: calc(100dvh - 66px);

    display: flex;
    flex-direction: column;
    padding: 0 16px;

    .video-swiper {
        width: 100%;
        
        @media (min-width: 600px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .swiper-wrapper {
            width: auto;
            
            > .swiper-slide {
                max-width: 400px;
                height: calc(100dvh - 82px);
                aspect-ratio: 9/16;
                border-radius: 8px;
                overflow: hidden;
                
                &.swiper-slide-next { opacity: 0.3; }
            }
        }
    }
`;

const CommentPlayer = styled.video`
    width: 100%;
    object-fit: fill;
`;

const CommentGalleryWrapper = styled.div`
    width: 100vw;
    height: calc(100dvh - 66px);

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2px;

    position: fixed;
    top: 66px;
    left: 0;

    background: #333333;
    overflow: auto;
    z-index: 1001;
    
    > img.item {
        width: 100%;
        aspect-ratio: 9/16;
        color: #333333;
        border: 1px solid black;
        background: #c2f4ff;
    }
`

export { CommentPlayerWrapper, CommentPlayer, CommentGalleryWrapper };