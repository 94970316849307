import { Provider } from "react-redux";
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import store from "../../store/store";

const Layout = () => {
  return (
    <>
      <Provider store={store}>
        <StyledLayoutWrapper>
          <Outlet />
        </StyledLayoutWrapper>
      </Provider>
    </>
  );
};

const StyledLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export default Layout;
