import styled from "styled-components";

const HeaderWrapper = styled.div`
    width: 100vw;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background: black;
    font-family: 'Pretendard', sans-serif;
    font-weight: 200;
`;

const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
    color: #ffffff;
    
    > span { 
        font-size: 20px;
        font-weight: 400;
    }
    
    > img { 
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-left: 12px;

        &.active {
            transition: 1s;
            transform: rotate(360deg);
        }
        
    }
`;

const HeaderRight = styled.div`
    color: #9d9d9d;
    font-size: 12px;
    font-weight: 300;

    span {
        margin-left: 8px;
        color: #ffffff;
    }
`;

export {
    HeaderWrapper,
    HeaderLeft,
    HeaderRight
};