import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';

import {getVideo} from "apis/VideoApi";

import VideoTop from "components/App/Share/Video/Layout/VideoTop";
import VideoLayout from "components/App/Share/Video/Layout/VideoLayout";

import {popupOpen} from "store/layerPopupSlice";


const VideoShareWrapper = styled.div`
    width: 100dvw;
    height: 100dvh;
    
    .video-swiper {
        width: 100%;
        height: 100%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        > .swiper-wrapper {
            width: auto;
        }
    }
`;

const VideoPlayerContainer = styled.video`
    width: 100vw;
    max-width: 440px;
    aspect-ratio: 9/16;
    object-fit: fill;
    
    background: #000000;
`;

const VideoShare = () => {
    const dispatch = useDispatch();

    const [swiper, setSwiper] = useState(null);
    const [videoList, setVideoList] = useState([]);
    const [searchParams] = useSearchParams();

    const videoSetting = {
        controls: false,
        loop: true,
        muted: true,
        autoPlay: true,
        playsInline: true,
        poster: '#'
    };

    const getShareVideo = async () => {
        return await getVideo(searchParams.get('st'), searchParams.get('type'), searchParams.get('id'))
    }

    const nextVideo = page => {
        if(swiper?.realIndex === 0) swiper.slideTo(page, 400);
    }

    useEffect(() => {
        getShareVideo().then(res => {
            setVideoList(res?.list);
        });
    }, []);

    return <VideoShareWrapper>
        <VideoTop/>
        <Swiper
            onInit={(el) => setSwiper(el)}
            direction={'vertical'}
            slidesPerView={1}
            spaceBetween={0}
            className="video-swiper"
            onBeforeSlideChangeStart={el => {
                if(el.isEnd && (el.slides.length - 1 === el.realIndex)) {
                    dispatch(popupOpen('MoveStore'))
                }
            }}
        >
            {
                videoList[0] !== null && videoList.map((item, idx) => {
                    return <SwiperSlide key={`video-${idx}`}>
                        <VideoPlayerContainer { ...videoSetting }
                            onPause={(el) => el.target.play()}>
                            <source src={(item.status === '460100' || item.status === '460101') ? item.filePath:undefined} type="video/mp4"/>
                        </VideoPlayerContainer>
                        <VideoLayout { ...item } nextVideo={nextVideo}/>
                    </SwiperSlide>
                })
            }
        </Swiper>
    </VideoShareWrapper>
}


export default VideoShare;