import {SingleCommentVideoWrapper} from "./SingleCommentVideo.styles";
import VideoLayout from "../Layout/VideoLayout";
import {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {CommentPlayer} from "../Comment/CommentContents.styles";


const SingleCommentVideo = video => {
    const [swiper, setSwiper] = useState(null);

    const videoSetting = {
        controls: false,
        loop: true,
        muted: true,
        autoPlay: true,
        playsInline: true,
        poster: '#'
    };

    useEffect(() => {
        console.log('video::', video)
    }, []);

    return <SingleCommentVideoWrapper>
        <Swiper
            onInit={(el) => setSwiper(el)}
            direction={'vertical'}
            slidesPerView={'auto'}
            spaceBetween={0}
            className="video-swiper"
        >
            <SwiperSlide>
                <CommentPlayer { ...videoSetting }>
                    <source src={video.filePath} type={'video/mp4'}/>
                </CommentPlayer>
                <VideoLayout { ...video }/>
            </SwiperSlide>
        </Swiper>
    </SingleCommentVideoWrapper>
}

export default SingleCommentVideo;