import styled from 'styled-components';
import BannerWrapper from '../../../../components/common/BannerWrapper/BannerWrapper';
import FeelkyBannerFifthHumanImg from '../../../../assets/img/feelky-banner-fifth-human.png';
import FeelkyResponsiveFifthHumanImg from '../../../../assets/img/feelky-responsive-banner-fifth-human.png';
import Title from '../../../../components/common/Title/Title';

const BannerFifth = () => {
  return (
    <BannerWrapper background="linear-gradient(90deg, #BCFFE0 -20.97%, #C6C4FF 114.58%)">
      <StyledBannerFifthWrapper>
        <StyledBannerRightWrapper $backgroundImg={FeelkyBannerFifthHumanImg}>
          <StyledBannerTitleWrapper>
            <Title
              effectColor="purple"
              fontColor="black"
              subFontColor="black"
              effectTitle="똑같은 콘텐츠와 소통 방식이 지겨운 당신에게"
              mainTitle={
                <>
                  <div>
                    새로운 소통법, <span>영상댓글로 티키타카</span>
                  </div>
                  <div />
                </>
              }
              mobileMainTitle={
                <>
                  <div>새로운 소통법,</div>
                  <div>
                    <span>영상댓글로 티키타카</span>
                  </div>
                </>
              }
              subTitle={
                <>
                  글로 하는 티키타카 이제 식상하지 않아? 재미있는 영상에 더
                  재미있는 영상댓글들! <br />
                  하는 재미, 보는 재미 그냥 다 재미있어! 영상댓글로 놀다보면
                  뭔가 더 가까워진 기분?
                </>
              }
              mobileSubTitle={
                <>
                  글로 하는 티키타카 이제 식상하지 않아? <br />
                  재미있는 영상에 더 재미있는 영상댓글들! 하는 재미, 보는 재미
                </>
              }
            />
          </StyledBannerTitleWrapper>
          <StyledBannerMobileImgWrapper>
            <img
              src={FeelkyResponsiveFifthHumanImg}
              alt="FeelkyResponsiveFifthHumanImg"
            />
          </StyledBannerMobileImgWrapper>
        </StyledBannerRightWrapper>
      </StyledBannerFifthWrapper>
    </BannerWrapper>
  );
};

export default BannerFifth;

const StyledBannerFifthWrapper = styled.div`
  width: 1090px;
  height: 100%;

  margin: 0 auto;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1090px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: stretch;
    padding: 0 10px;
    gap: 40px;
  }
`;

const StyledBannerRightWrapper = styled.div`
  width: 100%;
  height: 700px;
  background-image: url(${({ $backgroundImg }) => $backgroundImg});
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: end;

  @media (max-width: 800px) {
    align-items: center;
    /* gap: 40px; */
    background-image: none;
  }
`;

const StyledBannerTitleWrapper = styled.div`
  width: auto;
`;

export const StyledBannerSubBannerImg = styled.img`
  width: calc(100% - ${({ $reduceWidth }) => $reduceWidth || '0px'});
`;

const StyledBannerMobileImgWrapper = styled.div`
  display: none;

  > img {
    width: 100%;
  }

  @media (max-width: 800px) {
    display: block;
    width: 350px;
  }
  @media (max-width: 400px) {
    width: 100%;
    height: 50vh;
    > img {
      height: 100%;
      object-fit: contain;
    }
  }
`;
