import styled from "styled-components";

const VideoLayoutWrapper = styled.div`
    width: 100%;
    max-width: 440px;
    height: 100%;
    aspect-ratio: 9/16;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
    position: fixed;
    top: 0;
    left: 0;
`;

export { VideoLayoutWrapper };