import { EventTopViewWrapper } from './EventTopView.styles';

const EventTopView = (props) => {
    return <EventTopViewWrapper>
        <span className={'icon back'} onClick={() => {
            window.flutter_inappwebview.callHandler('CloseWebViewHandler', '');
        }}></span>
        {props.title}
    </EventTopViewWrapper>;
}

export default EventTopView;