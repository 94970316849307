import styled, { keyframes } from 'styled-components';

const CategorySliderMobile = ({ children, duration, reverse = false }) => {
  return (
    <LoopSlider
      style={{
        '--duration': `${duration}ms`,
        '--direction': reverse ? 'reverse' : 'normal',
      }}
    >
      <div className="inner">
        {children}
        {children}
      </div>
    </LoopSlider>
  );
};

export default CategorySliderMobile;

const loop = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const LoopSlider = styled.div`
  .inner {
    display: flex;
    width: fit-content;
    gap: 10px;
    white-space: nowrap;
    animation-name: ${loop};
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
  }
`;
