import { BrowserRouter, Routes, Route } from 'react-router-dom';

import createPublicRoutes from './pages';
import Layout from 'pages/Layout/Layout';

import Rank from 'pages/App/Challenge/Rank/Rank';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route>{createPublicRoutes()}</Route>
                </Route>
                <Route path="/app/challenge" element={<Rank />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
