import axios from 'axios';
import {useSearchParams} from "react-router-dom";

const API = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    withCredentials: true,
});


API.interceptors.request.use(config => {
    if(config.st) {
        switch(config.st) {
            case '990001':
                config.baseURL = process.env["REACT_APP_API_PROD"];
                break;
            case '990002':
                config.baseURL = process.env["REACT_APP_API_STG"];
                break;
            case '990003':
                config.baseURL = process.env["REACT_APP_API_DEV"];
                break;
            default:
                config.baseURL = process.env["REACT_APP_SERVER_URL"];
        }
    }

    return config;
}, error => {

    return Promise.reject(error);
})

const getVideo = async (serverType, videoType, videoId) => {
    const res = await API.get(`/share/video/${videoType}/${videoId}`, { st:serverType });
    console.log('비디오 리스트:', res.data?.result?.list);
    return res.data?.result;
}

const getComments = async (serverType, videoId) => {
    const res = await API.get(`/share/comments/${videoId}`, { st:serverType });
    return res.data?.result;
}

const getSingleVideo = async (serverType, videoId) => {
    const res = await API.get(`/share/single-video/${videoId}`, { st:serverType });
    return res.data?.result;
}

export {
    getVideo,
    getComments,
    getSingleVideo
}