import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import styled from "styled-components";

import {getSingleVideo} from "apis/VideoApi";
import CommentAppBar from "components/App/Share/Video/SingleComment/CommentAppBar";
import MainVideoBox from "components/App/Share/Video/SingleComment/MainVideoBox";
import SingleCommentVideo from "components/App/Share/Video/SingleComment/SingleCommentVideo";

const SingleCommentWrapper = styled.div`
    height: 100dvh;
    display: flex;
    flex-direction: column;
    background: #000000;
`;

const SingleCommentShare = () => {
    const [searchParams] = useSearchParams();
    const [mainVideo, setMainVideo] = useState(null);
    const [singleVideo, setSingleVideo] = useState(null);

    useEffect(() => {
        const getList = async () => {
            try {
                const res = await getSingleVideo(searchParams.get('st'), searchParams.get('id'));
                setMainVideo(res.mainVideo);
                setSingleVideo(res.singleVideo);
            } catch(error) {
                console.error('error:', error)
            }
        }

        getList();
    }, [searchParams])

    return <SingleCommentWrapper>
        <CommentAppBar/>
        { mainVideo && <MainVideoBox {...mainVideo}/> }
        { singleVideo && <SingleCommentVideo {...singleVideo}/> }
    </SingleCommentWrapper>
}

export default SingleCommentShare;