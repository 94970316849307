import styled from 'styled-components';
import BannerWrapper from '../../../../components/common/BannerWrapper/BannerWrapper';
import FeelkyBannerSixthHumanImg from '../../../../assets/img/feelky-banner-sixth-human.png';
import FeelkyResponsiveBannerSixthHumanImg from '../../../../assets/img/feelky-responsive-banner-sixth-human.png';
import Title from '../../../../components/common/Title/Title';

const BannerSixth = () => {
  return (
    <BannerWrapper background="#1A1A1A" mobileBackground="#262626">
      <StyledBannerSixthWrapper>
        <StyledBannerTitleWrapper>
          <Title
            effectTitle={<>척하는 사진에 속아 지친 당신에게</>}
            mainTitle={
              <>
                <div>
                  <span> 리얼타임 영상 속,</span> 비슷한
                </div>
                <div>
                  바이브를 가진 <span> 우리의 매칭 </span>
                </div>
              </>
            }
            subTitle={
              <>
                오늘 올린 나의 영상의 나의 프로필이 된다!
                <br />
                영상댓글로 티키타카하고 친구 하고 싶은 사람들 쏙쏙 골라
                <br />
                필키요청을 보내보자! 상대방도 나와 같은 마음이라면
                <br />
                영상이 사라지기 전까지 1:1 채팅 기회가 생긴다!
                <br />
                Today, Are you feeling lucky?
              </>
            }
            mobileSubTitle={
              <>
                오늘 올린 나의 영상의 나의 프로필이 된다!
                <br />
                영상댓글로 티키타카하고 친구 하고 싶은 사람들 쏙쏙 골라
              </>
            }
          />
        </StyledBannerTitleWrapper>
        <StyledBannerSixthImgWrapper>
          <StyledBannerSixthBigImg
            src={FeelkyBannerSixthHumanImg}
            alt="FeelkyBannerSixthHuman"
          />
          <StyledBannerSixthSmallImg
            src={FeelkyResponsiveBannerSixthHumanImg}
            alt="FeelkyResponsiveBannerSixthHumanImg"
          />
        </StyledBannerSixthImgWrapper>
      </StyledBannerSixthWrapper>
    </BannerWrapper>
  );
};

export default BannerSixth;

const StyledBannerSixthWrapper = styled.div`
  width: 1120px;
  height: 100%;

  margin: 0 auto;
  padding: 0 25px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1090px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: start;
  }
`;

const StyledBannerTitleWrapper = styled.div``;

const StyledBannerSixthImgWrapper = styled.div`
  width: 620px;

  > img {
    width: 100%;
  }

  @media (max-width: 1600px) {
    width: 50%;
  }

  @media (max-width: 800px) {
    width: 400px;
  }
  @media (max-width: 400px) {
    width: 100%;
    /* height: 40vh; */
    > img {
      height: 100%;
      object-fit: contain;
      /* height: 100%; */
    }
    /* width: 75%; */
    /* width: auto; */
    /* height: 15vh; */
  }
`;

const StyledBannerSixthBigImg = styled.img`
  @media (max-width: 800px) {
    display: none;
  }
`;
const StyledBannerSixthSmallImg = styled.img`
  @media (min-width: 801px) {
    display: none;
  }
`;
