import * as c from 'utils/siteCommon';
import {
    RankerPageWrapper,
    RankerListWrapper,
    RankerItem
} from './ExtendedRankerListView.styles';

import defaultProfile from 'assets/img/event-default-profile.jpg';

const ExtendedRankerListView = ({ list }) => {

    return (
        <RankerPageWrapper>
            <RankerListWrapper>
                {
                    list.map((item, idx) => {
                        return <RankerItem key={idx}>
                            <div className={'ranking'}>{idx+4}</div>
                            <div className={'profile'}>
                                <img src={item.thumbnail || defaultProfile} alt={'icon'}/>
                                <span>{item.name}</span>
                            </div>
                            <div className={'score'}>{c.setComma(item.score)}점</div>
                        </RankerItem>
                    })
                }
            </RankerListWrapper>
        </RankerPageWrapper>
    );
};

export default ExtendedRankerListView;
