import { cloneElement, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Portal = ({ children, onClose }) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    document.body.style.cssText = `
        overflow: hidden
      `;

    return () => {
      document.body.style.cssText = '';
    };
  }, []);

  if (typeof window === 'undefined') return null;

  if (!isMounted) return null;

  const onClick = (e) => {
    e.stopPropagation();
  };

  return ReactDOM.createPortal(
    <Positioner onClick={onClose}>
      {cloneElement(children, { onClick })}
    </Positioner>,
    document.getElementById('portal'),
  );
};

export default Portal;

export const Positioner = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background: #000000bf;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;
