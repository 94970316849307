import styled from "styled-components";


const CommentBasicWrapper = styled.div`
    width: 100%;
    height: calc(100dvh - 66px - 21px);
    padding: 0 16px;
`;

const CommentBasicInfo = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(${props => props.thumbnail}) no-repeat;
        background-size: cover;
        
        opacity: 0.2;
    }

    img {
        width: 90px;
        aspect-ratio: 1;
        margin: 46px 0;
    }
`;

const Description = styled.div`
    color: #ffffff;
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.2px;
    z-index: 1000;
    
    &:before {
        content: '${props => props.highlight}';
        color: #9990FF;
        font-weight: 400;
    }
`;


export { CommentBasicWrapper, CommentBasicInfo, Description };