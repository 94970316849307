import MapIcon from '../assets/img/map-icon.png';
import VideoIcon from '../assets/img/video-icon.png';
import LightningIcon from '../assets/img/lightning-icon.png';
import ChatIcon from '../assets/img/chat-icon.png';

const BottomBannerList = [
  {
    src: MapIcon,
    title: 'Near by',
    subTitle: (
      <p>
        내 주변 소식과 상황을 <br />
        실시간으로 알수 있어요!
      </p>
    ),
    mobileSubTitle: <p>내 주변, 실시간 소식</p>,
  },
  {
    src: VideoIcon,
    title: 'Real-time',
    subTitle: (
      <p>
        이미 찍어 놓은 동영상이 아닌,
        <br />
        방금 찍은 동영상들만!
      </p>
    ),
    mobileSubTitle: <p>내지금 찍은 영상들로만!</p>,
  },
  {
    src: LightningIcon,
    title: 'Connection',
    subTitle: (
      <p>
        취향과 분위기가 맞는 사람들과의
        <br />
        자연스러운 소통과 만남
      </p>
    ),
    mobileSubTitle: <p>비슷한 바이브 사람들</p>,
  },
  {
    src: ChatIcon,
    title: '1 : 1 Talk',
    subTitle: (
      <p>
        내 주변 또는 나와 취향이 비슷한
        <br /> 사람들과 나누는 시간제한 채팅
      </p>
    ),
    mobileSubTitle: <p>시간제한 1:1 채팅</p>,
  },
];

export default BottomBannerList;
