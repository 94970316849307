import * as c from 'utils/siteCommon';
import {
    TopRankerWrapper,
    RankerWrapper,
} from './MedalTopRankerListView.styles';

import defaultProfile from 'assets/img/event-default-profile.jpg';

const MedalTopRankerListView = ({ list }) => {
    let className = ['first', 'second', 'third']

    const setRankers = () => {
        let rankerWrapper = [];
        for(let i = 0; i < 3; i++) {
            list[i] ?
                rankerWrapper.push(
                    <RankerWrapper key={`RankWrap${i}`} className={className[i]}>
                        <div className={`thumbnail`}>
                            <img src={list[i].thumbnail} alt={`thumbnail`}/>
                        </div>
                        <div className={`title`}>{list[i].name}</div>
                        <div className={`score`}>{c.setComma(list[i].score)}</div>
                    </RankerWrapper>
                )
                : rankerWrapper.push(
                    <RankerWrapper key={`RankWrap${i}`} className={className[i]}>
                        <div className={`thumbnail`}>
                            <img src={defaultProfile} alt={`thumbnail`}/>
                        </div>
                        <div className={`title`}>???</div>
                        <div className={`score`}>0</div>
                    </RankerWrapper>
                )
        }

        return rankerWrapper;
    }

    return (
        <TopRankerWrapper>
            { setRankers() }
        </TopRankerWrapper>
    );
};

export default MedalTopRankerListView;
