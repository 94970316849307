/* eslint-disable react/no-array-index-key */
import styled from 'styled-components';
import BannerWrapper from '../../../../components/common/BannerWrapper/BannerWrapper';

import FeelkyIcon from '../../../../assets/img/feelky_icon.svg';

import CategorySliderMobile from './components/CategorySliderMobile/CategorySliderMobile';
import Banner from './components/Banner/Banner';
import Category from './components/Category/Category';
import NewCategory from './components/NewCategory/NewCategory';
import {
  LeftCategory,
  RightCategory,
  TotalCategory,
} from '../../../../data/categoryData';
import BottomBannerList from '../../../../data/feelkyFeatures';

const BannerSecond = () => {
  const DURATION = 70000;
  const ROWS = 2;
  const ELEMENTS_PER_ROW = 16;

  const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
  const shuffle = (arr) => [...arr].sort(() => 0.5 - Math.random());

  return (
    <BannerWrapper background="linear-gradient(90deg, #bcffe0 -20.97%, #c6c4ff 114.58%)">
      <StyledBannerSecondWrapper>
        <StyledBannerSecondTopBannerLayout>
          <StyledBannerSecondCategoryWrapper>
            <NewCategory categorys={LeftCategory} />
            <StyledBannerSecondFeelkyIconWrapper
              src={FeelkyIcon}
              alt="Feelky Icon"
            />
            <NewCategory categorys={RightCategory} />
          </StyledBannerSecondCategoryWrapper>
          <StyledBannerSecondCategoryMobileWrapper>
            <StyledBannerSecondFeelkyIconWrapper
              src={FeelkyIcon}
              alt="Feelky Icon"
            />
            <div>
              <ElementsList>
                {[...new Array(ROWS)].map((_, i) => (
                  <CategorySliderMobile
                    key={i}
                    duration={random(DURATION - 5000, DURATION + 5000)}
                    reverse={i % 2}
                  >
                    {shuffle(TotalCategory)
                      .slice(0, ELEMENTS_PER_ROW)
                      .map(({ icon, text, option }, index) => (
                        <CategoryWrapper key={index}>
                          <Category icon={icon} text={text} option={option} />
                        </CategoryWrapper>
                      ))}
                  </CategorySliderMobile>
                ))}
              </ElementsList>
            </div>
          </StyledBannerSecondCategoryMobileWrapper>
        </StyledBannerSecondTopBannerLayout>
        <StyledBannerSecondBottomWrapper>
          <StyledBannerSecondBottomBannerLayout>
            {BottomBannerList.map(
              ({ src, title, subTitle, mobileSubTitle }) => (
                <Banner
                  key={title}
                  src={src}
                  title={title}
                  subTitle={subTitle}
                  mobileSubTitle={mobileSubTitle}
                />
              ),
            )}
          </StyledBannerSecondBottomBannerLayout>
        </StyledBannerSecondBottomWrapper>
      </StyledBannerSecondWrapper>
    </BannerWrapper>
  );
};

export default BannerSecond;

const StyledBannerSecondWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 150px;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
  }
`;

const StyledBannerSecondCategoryWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 30px;
  overflow: hidden;

  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledBannerSecondCategoryMobileWrapper = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledBannerSecondFeelkyIconWrapper = styled.img`
  width: 150px;

  @media (max-width: 800px) {
    width: 110px;
  }

  @media (max-width: 400px) {
    width: 100px;
  }
`;

const ElementsList = styled.div`
  width: 100vw;

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 13px 0;

  padding-top: 16px;
  /* 모바일 버전 세로 스크롤 생길시 해제 */
  /* overflow: hidden; */
`;

const CategoryWrapper = styled.div`
  padding: 0 3px;
`;

const StyledBannerSecondBottomWrapper = styled.div`
  width: 1070px;
  margin: 65px auto 0 auto;
  height: 60%;

  @media (max-width: 1070px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    margin: 0 auto;
    height: auto;
    aspect-ratio: 1 / 0.787;
  }
`;

const StyledBannerSecondTopBannerLayout = styled.div``;

const StyledBannerSecondBottomBannerLayout = styled.div`
  width: 100%;

  padding: 8px 20px;

  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 800px) {
    height: 100%;

    padding: 20px 25px;

    margin: 0 auto;

    flex-wrap: wrap;
    align-items: normal;
    gap: 10px;
  }

  @media (max-width: 400px) {
    height: auto;
    aspect-ratio: 1 / 0.787;
    padding: 20px 25px;

    margin: 0 auto;

    flex-wrap: wrap;
    align-items: normal;
    gap: 10px;
  }
`;
