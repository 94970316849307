import { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

const getRandomPosition = (max) => Math.floor(Math.random() * max);

const RandomDots = ({ width, height, numberOfDots }) => {
  const [dots, setDots] = useState([]);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const initialDots = Array.from({ length: numberOfDots }, (_, index) => ({
      id: index,
      x: getRandomPosition(width),
      y: getRandomPosition(height),
    }));
    setDots(initialDots);

    const intervalId = setInterval(() => {
      setVisible(false);

      setTimeout(() => {
        setDots((prevDots) =>
          prevDots.map((dot) => ({
            ...dot,
            x: getRandomPosition(width),
            y: getRandomPosition(height),
          })),
        );
        setVisible(true);
      }, 1000);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [width, height, numberOfDots]);

  const dotElements = useMemo(
    () =>
      dots.map((dot) => (
        <Dot
          key={dot.id}
          style={{ left: dot.x, top: dot.y }}
          $visible={visible}
        >
          <div />
        </Dot>
      )),
    [dots, visible],
  );

  return (
    <Container width={width} height={height}>
      {dotElements}
    </Container>
  );
};

const Container = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  position: relative;
`;

const Dot = styled.div`
  width: 10px;
  aspect-ratio: 1 / 1;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  background: rgba(173, 253, 222, 0.5);
  border-radius: 50%;

  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 800px) {
    width: 7px;
  }

  > div {
    width: 60%;
    aspect-ratio: 1 / 1;
    background: #adfdde;
    border-radius: 50%;
  }
`;

export default RandomDots;
