import styled from 'styled-components';

import FeelkyLoadViewImg from '../../../../assets/img/feelky-loadview.png';
import FeelkyResponsiveLoadViewImg from '../../../../assets/img/feelky-responsive-loadview.png';
import FeelkyBannerFirstHumanImg from '../../../../assets/img/feelky-banner-first-human.png';

import GooglePlayIcon from '../../../../assets/img/googlePlay-icon.png';
import AppleIcon from '../../../../assets/img/apple_icon.svg';

import CustomBackground, {
  Effect,
} from './components/CustomBackground/CustomBackground';
import RandomDots from './components/RandomDots/RandomDots';
import RadarComponent from './components/Rader/Rader';
import Title from '../../../../components/common/Title/Title';
import downloadApp from '../../../../utils/downloadApp';
import downloadAppAndroid from '../../../../utils/downloadAppAndroid';
import downloadAppIos from '../../../../utils/downloadAppIos';

const BannerFirst = () => {
  const downloadStores = [
    {
      image: GooglePlayIcon,
      title: 'Google Play',
      click: () => downloadAppAndroid(),
    },
    {
      image: AppleIcon,
      title: 'App Store',
      click: () => downloadAppIos(),
    },
  ];

  return (
    <BannerWrapper
      $backgroundImage={FeelkyLoadViewImg}
      $responsiveImage={FeelkyResponsiveLoadViewImg}
    >
      <CustomBackground>
        <Effect>
          <RadarComponent width={600} />
        </Effect>
        <Effect>
          <RandomDots width={500} height={500} numberOfDots={30} />
        </Effect>

        <StyledBannerFirstWrapper>
          <StyledBannerFirstRightSideWrapper>
            <Title
              mainTitle={
                <>
                  <StyledBannerFirstMainTitle>
                    <span>지금, 내 주변</span>에서
                  </StyledBannerFirstMainTitle>
                  <StyledBannerFirstMainTitle>
                    가장 <span>핫한 곳은</span> 어딜까?
                  </StyledBannerFirstMainTitle>
                </>
              }
              subTitle={
                <StyledBannerFirstSubTitle>
                  오늘, 바로 이 순간, 내 주위 사람들은 뭐할까? <br />
                  서로의 상황 속 분위기를 보다 생생한 영상으로 공유하며 <br />
                  뜻밖에 우연한 재미의 순간으로 바꿔보는건 어때요?
                </StyledBannerFirstSubTitle>
              }
            />

            <StyledBannerFirstDownloadWrapper>
              {downloadStores.map(({ image, title, click }) => (
                <StyledBannerFirstDownloadButton key={title} onClick={click}>
                  <img src={image} alt={`${title} icon`} />
                  {title}
                </StyledBannerFirstDownloadButton>
              ))}
            </StyledBannerFirstDownloadWrapper>
          </StyledBannerFirstRightSideWrapper>
          <StyledBannerFirstRightSideMobileWrapper>
            <Title
              mainTitle={
                <>
                  <div style={{ textAlign: 'center' }}>
                    <span>지금, 내 주변</span>에
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    뭔가 <span>재밌는 일</span> 없을까?
                  </div>
                </>
              }
            />
            <StyledBannerFirstMobileDownloadWrapper>
              <StyledBannerFirstDownloadButton onClick={downloadApp}>
                앱 다운로드
              </StyledBannerFirstDownloadButton>
            </StyledBannerFirstMobileDownloadWrapper>
          </StyledBannerFirstRightSideMobileWrapper>
          <StyledBannerFirstLeftSideWrapper>
            <StyledBannerFirstMainBannerWrapper>
              <img
                src={FeelkyBannerFirstHumanImg}
                alt="FeelkyBannerFirstHumanImg"
              />
            </StyledBannerFirstMainBannerWrapper>
          </StyledBannerFirstLeftSideWrapper>
        </StyledBannerFirstWrapper>
      </CustomBackground>
    </BannerWrapper>
  );
};

export default BannerFirst;

const StyledBannerFirstMainTitle = styled.div`
  font-size: 50px;
  font-weight: 300;
  color: #ffffff;

  span {
    font-weight: 500;
  }

  @media (max-width: 1600px) {
    font-size: 40px;
  }
  @media (max-width: 800px) {
    font-size: 35px;
    text-align: center;
  }
  @media (max-width: 400px) {
    font-size: 30px;
  }
`;

const StyledBannerFirstSubTitle = styled.p`
  font-size: 18px;
  font-weight: 200;

  color: #ffffff;
`;

const BannerWrapper = styled.div`
  width: 100%;
  height: 100%;

  padding-top: 84px;

  background: #1a1a1a;
  background-image: url(${({ $backgroundImage }) => $backgroundImage || ''});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 800px) {
    padding-top: 100px;
    background-image: url(${({ $responsiveImage }) => $responsiveImage});
    background-size: contain;
    background-position: center;
  }
`;

const StyledBannerFirstWrapper = styled.div`
  width: 1090px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 100;

  @media (max-width: 1090px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: normal;
    padding-top: 4%;
    gap: 36px;
  }
`;

// Banner Right Side
const StyledBannerFirstRightSideWrapper = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledBannerFirstDownloadWrapper = styled.div`
  margin-top: 40px;

  display: flex;
  gap: 10px;
`;

const StyledBannerFirstDownloadButton = styled.div`
  width: 131px;
  height: 36px;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 200;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  border: 1px solid #ffffff;
  border-radius: 30px;

  transition: 0.5s;

  cursor: pointer;

  > img {
    transition: 0.5s;
    width: 16px;
  }

  &:hover {
    background: #ffffff;
    border: 1px solid none;
    color: #000000;
  }
  &:last-of-type {
    &:hover {
      img {
        filter: invert(100%);
      }
    }
  }
`;

// Banner Left Side
const StyledBannerFirstLeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    margin-top: 4%;
  }
`;

const StyledBannerFirstMainBannerWrapper = styled.div`
  width: 470px;
  > img {
    width: 100%;
  }

  @media (max-width: 1600px) {
    width: 360px;
  }
  @media (max-width: 800px) {
    width: 300px;
  }
  @media (max-width: 400px) {
    width: 80%;
  }
`;

const StyledBannerFirstRightSideMobileWrapper = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    display: flex;
  }
`;

const StyledBannerFirstMobileDownloadWrapper = styled.div`
  margin-top: 10px;
`;
