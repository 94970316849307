import styled from "styled-components";

import iconLocation from "assets/icon/share_video_category_location.svg";

const VideoTopContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    padding: 24px 8px 24px 20px;
    z-index: 2000;
    
    > .location-category-wrap {
        display: inline-flex;
        align-items: center;
        
        padding: 8px 20px 8px 12px;
        border: 1px solid RGBA(255, 255, 255, 0.3);
        border-radius: 32px;
        background: RGBA(0, 0, 0, 0.3);
        color: #ffffff;
        font-size: 14px;
        font-weight: 250;
        
        &:before {
            content: url(${iconLocation});
            width: 18px;
            height: 22px;
            margin-right: 6px;
        }
    }
    
    > .icon-wrap {
        display: flex;
        
        > span {
            padding: 12px;
            
            > img {
                width: 22px;
            }
        }
    }
`;

export { VideoTopContainer };