import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper/modules';
import React, { useEffect, useRef, useState } from 'react';
import BannerFifth from './components/BannerFifth/BannerFifth';
import BannerFourth from './components/BannerFourth/BannerFourth';
import BannerFirst from './components/BannerFirst/BannerFirst';
import BannerLast from './components/BannerLast/BannerLast';
import BannerSecond from './components/BannerSecond/BannerSecond';
import BannerSixth from './components/BannerSixth/BannerSixth';
import BannerThird from './components/BannerThird/BannerThird';
import SplashPopup from './components/SplashPopup/SplashPopup';

import TopBar from 'components/Tobar/Topbar';

const SwiperSlidesWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const SwiperWrapper = styled(Swiper)`
  width: 100%;
  height: 100%;

  @media (min-width: 801px) {
    display: none;
  }
`;

const WebWrapper = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  @media (max-width: 800px) {
    display: none;
  }
`;
const SwiperSlides = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const swiperRef = useRef(null);

  const handleResize = () => {
    const isNowMobile = window.innerWidth <= 800;
    if (isNowMobile !== isMobile) {
      if (isNowMobile) {
        const { scrollY } = window;
        const viewportHeight = window.innerHeight;
        const slideIndex = Math.round(scrollY / viewportHeight);
        if (swiperRef.current && swiperRef.current.swiper) {
          swiperRef.current.swiper.slideTo(slideIndex, 0);
        }
      } else {
        if (swiperRef.current && swiperRef.current.swiper) {
          swiperRef.current.swiper.slideTo(0, 0);
        }
        window.scrollTo(0, 0);
      }
      setIsMobile(isNowMobile);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return (
    <>
      <TopBar/>
      <SwiperSlidesWrapper>
        {isMobile ? (
          <SwiperWrapper
            ref={swiperRef}
            direction="vertical"
            slidesPerView={1}
            mousewheel
            modules={[Mousewheel, Pagination]}
          >
            {React.Children.map(children, (child) => (
              <SwiperSlide>{child}</SwiperSlide>
            ))}
          </SwiperWrapper>
        ) : (
          <WebWrapper>{children}</WebWrapper>
        )}
      </SwiperSlidesWrapper>
    </>
  );
};

const Landing = () => {
  return (
    <LandingWrapper>
      <SwiperSlides>
        <BannerFirst />
        <BannerSecond />
        <BannerThird />
        <BannerFourth />
        <BannerFifth />
        <BannerSixth />
        <BannerLast />
      </SwiperSlides>
      <SplashPopup />
    </LandingWrapper>
  );
};

export default Landing;

const LandingWrapper = styled.div`
  height: 100%;
`;
