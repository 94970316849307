export const LeftCategory = [
  { item: [{ icon: '👍', text: '지금 재밌고 행복하면 최고' }] },
  {
    item: [
      { icon: '🌟', text: '기회는 잡은 사람의 것' },
      { icon: '📍', text: '내 주변에 이런 일이?', option: 'glass' },
    ],
  },
  {
    item: [
      {
        icon: '🏞️',
        text: '가보고 싶었던 그곳, 지금 상태는?',
        option: 'glass',
      },
    ],
  },
  { item: [{ icon: '🤟', text: '친구도, 사랑도 가까운게 최고' }] },
];

export const RightCategory = [
  { item: [{ icon: '📢', text: '지난 소식 말고 지금 소식 원해요!' }] },
  {
    item: [
      { icon: '👩‍❤️‍💋‍👨', text: '외로운게 뭐야?' },
      { icon: '🔮', text: '매일 새로운 에피소드로 가득', option: 'glass' },
    ],
  },
  {
    item: [
      { icon: '⚡️', text: '7시간 안에 완전 럭키비키잖아?', option: 'glass' },
    ],
  },
  { item: [{ icon: '🧞‍♂️', text: 'MZ들의 놀이터', option: 'glass' }] },
];

export const TotalCategory = [...LeftCategory, ...RightCategory].flatMap(
  (category) => category.item,
);
