import styled from "styled-components";

import btnBack from "assets/icon/common_back_button.svg";

const EventTopViewWrapper = styled.div`
    width: 100vw;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    background: #000000;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;

    > .icon.back {
        width: 48px;
        height: 48px;
        top: 0;
        left: 0;
        position: absolute;
        background: url(${btnBack}) no-repeat center center;
    }
`

export {
    EventTopViewWrapper
};