import Trophy from 'assets/img/welcom-page-trophy.svg';
import {
    WelcomePopupContainer,
    PageTitle,
    PageSubTitle
} from './WelcomePopup.styles'
import {useEffect, useState} from "react";


const WelcomePopup = () => {
    const [popupFlag, setPopupFlag] = useState(true);

    useEffect(() => {
        setTimeout(() => { setPopupFlag(false) }, 3500);
    }, [])

    return <WelcomePopupContainer $flag={popupFlag} onClick={() => setPopupFlag(false)}>
        <PageTitle>실시간 영상으로</PageTitle>
        <PageSubTitle>가장 사랑받을<br/>크루는?</PageSubTitle>
        <img src={Trophy} alt={''}/>
    </WelcomePopupContainer>
}

export default WelcomePopup;