import {useState} from "react";

import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';

import {CommentGalleryWrapper, CommentPlayer, CommentPlayerWrapper} from "./CommentContents.styles";
import VideoLayout from "../Layout/VideoLayout";

const CommentContents = props => {
    const [swiper, setSwiper] = useState(null);
    const videoSetting = {
        controls: false,
        loop: true,
        muted: true,
        autoPlay: true,
        playsInline: true,
        poster: '#'
    };

    const onClickThumbnail = page => {
        props.setGalleryShow(false)
        swiper.slideTo(page, 750);
    }

    return <CommentPlayerWrapper>
        <Swiper
            onInit={(el) => setSwiper(el)}
            direction={'vertical'}
            slidesPerView={'auto'}
            spaceBetween={0}
            className="video-swiper"
        >
            {
                props.list.map((item, idx) => {
                    return <SwiperSlide key={`comment-${idx}`}>
                        <CommentPlayer { ...videoSetting }>
                            <source src={item.filePath} type={'video/mp4'}/>
                        </CommentPlayer>
                        <VideoLayout { ...item }/>
                    </SwiperSlide>
                })
            }
        </Swiper>
        {
            props.galleryShow && <CommentGalleryWrapper>
                {
                    props.list.map((item, idx) => {
                        return <img
                            src={item.thumbnail}
                            className="item"
                            key={`gallery-${idx}`}
                            alt={'thumbnail'}
                            onClick={() => onClickThumbnail(idx)}
                        />
                    })
                }
            </CommentGalleryWrapper>
        }
    </CommentPlayerWrapper>
}

export default CommentContents;