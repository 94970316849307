import styled from "styled-components";
import btnBack from "assets/icon/common_back_button.svg";
import iconGallery from "assets/icon/share_comment_gallery.svg";
import iconGalleryOff from "assets/icon/share_comment_gallery_off.svg";
import iconTimeLeft from "assets/icon/share_comment_time_left.svg";

const CommentHeaderWarpper = styled.div`
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 8px;
    
    > .icon {
        flex: 0 0 auto;
        width: 46px;
        height: 46px;
        
        background-repeat: no-repeat;
        background-position: center center;
        
        &.back {
            background-image: url(${btnBack});
        }
        
        &.gallery {
            background-image: url(${iconGallery});
            
            &.off {
                background-image: url(${iconGalleryOff});
            }
        }

        &.time-left {
            position: relative;
            background-image: url(${iconTimeLeft});
            
            > .message-popup {
                position: absolute;
                right: 8px;
                bottom: -46px;
                padding: 12px;
                border-radius: 8px;
                background: #7d7ae0;
                color: #ffffff;
                font-size: 14px;
                font-weight: 300;
                white-space: nowrap;
                z-index: 1002;

                &:after {
                    content: "";
                    position: absolute;
                    top: -8px;
                    right: 10px;
                    border-top: 8px solid #7d7ae0;
                    border-right: 8px solid transparent;
                    border-bottom: 0 solid transparent;
                    border-left: 8px solid transparent;
                    rotate: 180deg;
                }
            }
        }
    }
    
    > .title {
        flex: 1 1 auto;
        color: #ffffff;
        font-size: 20px;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const EndTimeMessageWrapper = styled.span`
    
`

export { CommentHeaderWarpper, EndTimeMessageWrapper };