import {styled, css} from "styled-components";
import eventCrown from "assets/icon/event-crown.svg";

const _size= {
    first: {
        title: '1등',
        percent: 25,
        order: 2,
        linearGradient: ['#bcffe0', '#c6c4ff']
    },
    second: {
        title: '2등',
        percent: 18,
        order: 1,
        linearGradient: ['#dcdcdc', '#717171']
    },
    third: {
        title: '3등',
        percent: 18,
        order: 3,
        linearGradient: ['#e5cfba', '#a87149']
    },
    border: 3
}

const TopRankerWrapper = styled.div`
    padding: 24px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    background: #1a1a1a;
`;

const RankerWrapper = styled.div`
    width: ${props => _size[props.className].percent}%;
    order: ${props => _size[props.className].order};
    
    &:before {
        content: '${props => _size[props.className].title}';
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 12px;
        font-size: 20px;
        ${props => {
            switch(props.className) {
                case 'first':
                    return css`
                        color: #1a1a1a;
                    `;
                default:
                    return css`
                        background: linear-gradient(
                            ${_size[props.className].linearGradient[0]},
                            ${_size[props.className].linearGradient[1]}
                        );
                        background-clip: text;
                        color: transparent;
                    `;
            }
        }};
    }
    
    > .thumbnail {
        width: 100%;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 50%;
        background: linear-gradient(
            90deg,
            ${props => _size[props.className].linearGradient[0]}, 
            ${props => _size[props.className].linearGradient[1]}
        );
        
        ${props => {
            switch(props.className) {
                case 'first':
                    return css`
                        box-shadow: 0 0 20px 0 #80F7FF;
                    `;
                default:
                    return css``;
            }
        }};

        > img {
            width: calc(100% - ${_size["border"] * 2}px);
            aspect-ratio: 1;
            border-radius: 50%;
        }

        ${props => {
            switch (props.className) {
                case 'first':
                    return css`
                    &:before {
                        content: '';
                        width: calc(100% - 34%);
                        aspect-ratio: 3/2;
                        position: absolute;
                        top: calc(25vw * 0.3 * -1 - 6px);
                        left: 18%;
                        background: url(${eventCrown}) no-repeat;
                        background-size: contain;
                    }
                `;
                default:
                    return css``;
            }
        }};
    }

    > .title {
        margin-top: 8px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > .score {
        margin-top: 4px;
        color: #c4c4c4;
        font-size: 18px;
        font-weight: ${props => props.className === 'first' ? 400:200};
        text-align: center;
        ${props => props.className === 'first' && css`
            background: linear-gradient(
                90deg,
                ${_size[props.className].linearGradient[0]},
                ${_size[props.className].linearGradient[1]}
            );
            background-clip: text;
            color: transparent;
        `
    }
`;

export {
    TopRankerWrapper,
    RankerWrapper,
}