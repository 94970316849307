import styled from "styled-components";


const CommentAppBarWrapper = styled.div`
    padding: 14px 0;

    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
`

const CommentAppBar = () => {

    return <CommentAppBarWrapper>영상 댓글</CommentAppBarWrapper>
}

export default CommentAppBar;