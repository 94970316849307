/* eslint-disable no-unused-vars */
import styled, { keyframes } from 'styled-components';

const RadarComponent = ({ width }) => {
  const dangerTheme = {
    color: '#adfdde',
  };

  return (
    <Wave $width={width} theme={dangerTheme}>
      <Circle theme={dangerTheme} delay="0s" />
      <Circle theme={dangerTheme} delay="0.3s" />
      <Circle theme={dangerTheme} delay="0.6s" />
      <Content>
        <i className="fa fa-bell" />
      </Content>
    </Wave>
  );
};
const circleOpacity = keyframes`
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(1);
  }
`;

const Wave = styled.div`
  position: relative;
  width: ${({ $width }) => $width}px;
  aspect-ratio: 1 / 1;
  text-align: center;
  line-height: 200px;
  font-size: 28px;
  color: ${({ theme }) => theme.color};
`;

const Circle = styled.div`
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  width: ${({ solid }) => (solid ? '100%' : 'calc(100% - 6px)')};
  height: ${({ solid }) => (solid ? '100%' : 'calc(100% - 6px)')};
  border: ${({ solid }) => (solid ? 'none' : '3px solid')};
  background: ${({ solid, theme }) => (solid ? theme.color : 'none')};
  border-color: ${({ solid, theme }) => (solid ? 'none' : theme.color)};
  animation: ${circleOpacity} 2s infinite;
  animation-delay: ${({ delay }) => delay};
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
`;

export default RadarComponent;
