import styled from "styled-components";

const SingleCommentVideoWrapper = styled.div`
    width: 100vw;
    height: calc(100dvh - 52px - 46px - 10px);

    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 0 16px;

    .video-swiper {
        width: 100%;

        @media (min-width: 600px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .swiper-wrapper {
            width: auto;

            > .swiper-slide {
                max-width: 400px;
                height: calc(100dvh - 52px - 46px - 10px);
                aspect-ratio: 9/16;
                border-radius: 8px;
                overflow: hidden;

                &.swiper-slide-next { opacity: 0.3; }
            }
        }
    }
`;

export { SingleCommentVideoWrapper };