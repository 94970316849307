import {useEffect, useRef, useState} from "react";
import moment from "moment";
import 'moment/locale/ko';

import {VideoBottomContainer} from './VideoBottom.styles';

const VideoBottom = props => {
    const [moreTags, setMoreTags] = useState(false);
    const [endTime, setEndTime] = useState('');
    const tagsEl = useRef(null);
    const endTimeEL = useRef(null);

    const timeConverter = () => {
        if(props?.status === '460101') {
            setEndTime(`종료까지 00시간 00분`);
        } else if(props?.videoType === '400101' && props?.status === '460100') {
            let timeDiff = moment(props.videoEndTime).diff(moment().format('YYYY-MM-DD HH:mm:ss'));
            let timeConvert = moment.duration(timeDiff, 'milliseconds').asHours();

            let _hour = Math.floor(timeConvert);
            let _minute = Math.floor((timeConvert - _hour) * 60);

            endTimeEL.current?.classList.add('extra');

            if(0 < _hour) {
                setEndTime(`종료까지 ${_hour}시간 ${_minute}분`);
            } else {
                endTimeEL.current?.classList.add('ending')
                setEndTime(`종료까지 ${_minute}분`);
            }
        } else {
            let timeDiff = moment().diff(moment(props.videoCreatedAt).format('YYYY-MM-DD HH:mm:ss'));
            let timeMs = moment.duration(timeDiff, 'milliseconds');

            if(timeMs.asSeconds() < 60) setEndTime(`방금 전`);
            else if(timeMs.asMinutes() < 60) setEndTime(`${timeMs.asMinutes().toFixed(0)}분 전`);
            else if(timeMs.asHours() < 24) setEndTime(`${timeMs.asHours().toFixed(0)}시간 전`);
            else setEndTime(`${timeMs.asDays().toFixed(0)}일 전`);
        }
    }

    const extraConvert = extraTime => {
        let timeSec = moment.duration(extraTime, 'seconds');

        if(0 < timeSec.asMinutes() && timeSec.asMinutes() < 60) return `${Math.floor(timeSec.asMinutes())}분`;
        else if(0 < Math.floor(timeSec.asHours())) return `${Math.floor(timeSec.asHours())}시간`
    }

    const checkedTagOverFlow = () => {
        if(tagsEl.current) {
            if(tagsEl.current.clientWidth < tagsEl.current.scrollWidth) {
                setMoreTags(true)
            }
        }
    }

    useEffect(() => {
        checkedTagOverFlow();
        timeConverter();
    }, []);

    return <VideoBottomContainer $extraTime={extraConvert(props.extraTime)}>
        <div className={'badge-wrap'}>
            <span className={'location'}>나와의 거리</span>
            <span className={'address'}>{`${props.address.split(' ')[0]} ${props.address.split(' ')[1]}`}</span>
        </div>
        <div className={'title-wrap'}>{props.title}</div>
        {
            props.tags && <div className={'tag-wrap'}>
                <span ref={tagsEl} className={'tags'}>{props.tags}</span>
                {
                    moreTags && <span className={'more'} onClick={(e) => {
                        setMoreTags(false);
                        tagsEl.current.classList.add('active');
                    }}>더보기</span>
                }
            </div>
        }
        <div className={'additional-wrap'}>
            <span className={`nickname`}>{props.nickname}</span>
            {/*<span ref={endTimeEL} className={`time`}>{timeConverter()}</span>*/}
            <span ref={endTimeEL} className={`time`}>{endTime}</span>
        </div>
    </VideoBottomContainer>
}

export default VideoBottom;