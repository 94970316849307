/* eslint-disable no-unused-vars */
import styled from 'styled-components';

const Banner = ({ src, title, subTitle, mobileSubTitle }) => {
  return (
    <StyledBannerWrapper>
      <div>
        <StyledBannerIconWrapper>
          <img src={src} alt="Banner Ixcon" />
        </StyledBannerIconWrapper>
        <StyledTitleWrapper>
          <StyledBannerTitle>{title}</StyledBannerTitle>
          <StyledBannerSubTitle>{subTitle}</StyledBannerSubTitle>
          <StyledBannerMobileSubTitle>
            {mobileSubTitle}
          </StyledBannerMobileSubTitle>
        </StyledTitleWrapper>
      </div>
    </StyledBannerWrapper>
  );
};

export default Banner;

export const StyledBannerWrapper = styled.div`
  width: 25%;
  aspect-ratio: 1 / 1.147;
  background: #ffffff66;

  border-radius: 20px;
  opacity: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    gap: 10px;
  }

  @media (max-width: 800px) {
    /* width: 10%; */
    /* height: 45%; */
    width: calc(50% - 5px);
    aspect-ratio: auto;
    /* aspect-ratio: 1 / 0.787; */
    /* flex: 1 1 calc(50% - 25px); */
    /* flex: 1 1 40%; */
  }
`;

export const StyledBannerIconWrapper = styled.div`
  height: 66px;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    height: 100%;
  }

  @media (max-width: 1600px) {
    height: 56px;
  }
  @media (max-width: 800px) {
    height: 46px;
  }
  @media (max-width: 400px) {
    height: 36px;
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledBannerTitle = styled.h1`
  font-family: Gmarket Sans;
  font-size: 24px;
  font-weight: 200;
  color: #412cab;

  @media (max-width: 1600px) {
    font-size: 22px;
  }
  @media (max-width: 800px) {
    font-size: 19px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

export const StyledBannerSubTitle = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 200;
  line-height: 18px;
  text-align: center;
  color: #434343;

  margin-top: 10px;

  @media (max-width: 400px) {
    display: none;
  }
`;

export const StyledBannerMobileSubTitle = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 200;
  line-height: 18px;
  text-align: center;
  color: #434343;

  display: none;

  margin-top: 10px;

  @media (max-width: 400px) {
    display: contents;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
