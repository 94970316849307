import styled from 'styled-components';

export const StyledTopBarWrapper = styled.div`
  height: 84px;

  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;

  background-color: #1a1a1a80;

  @media (max-width: 800px) {
    width: 100vw;
    height: 59px;
  }
`;

export const StyledTopBarLayout = styled.div`
  max-width: 1100px;
  width: 100%;
  height: 100%;

  margin: 0 auto;
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTopBarLogoWrapper = styled.div`
  height: 32px;

  padding-top: 2.14px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    img {
      width: 111.5px;
    }
  }
`;

export const StyledTopBarLogoIcon = styled.img`
  margin-bottom: 3px;
  margin-left: 10px;
`;

export const StyledTopBarAppInstallButton = styled.div`
  width: 116px;
  height: 32px;

  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 500;
  text-align: left;

  color: #000000;
  background: linear-gradient(90deg, #bcffe0 -20.97%, #c6c4ff 114.58%);
  cursor: pointer;

  @media (max-width: 800px) {
    width: 100px;
    height: 32px;

    font-size: 14px;
    font-weight: 400;
  }
`;
