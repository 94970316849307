import { useDispatch } from "react-redux";
import { popupOpen } from "store/layerPopupSlice";

import { VideoTopContainer } from "./VideoTop.styles";

import iconChat from "assets/icon/share_video_chat_off.svg";
import iconAlarm from "assets/icon/share_video_alarm.svg";

const VideoTop = props => {
    const dispatch = useDispatch();

    const onClickMoveStore = () => {
        dispatch(popupOpen('MoveStore'))
    }

    return <VideoTopContainer>
        <div className={'location-category-wrap'} onClick={onClickMoveStore}>내 주변 영상 보러가기</div>
        <div className={'icon-wrap'}>
            <span onClick={onClickMoveStore}><img src={iconChat} alt={'chat'}/></span>
            <span onClick={onClickMoveStore}><img src={iconAlarm} alt={"alarm"}/></span>
        </div>
    </VideoTopContainer>
}


export default VideoTop;