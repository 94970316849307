/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Pixel = styled.div`
  width: ${(props) => props.$pixelSize}px; /* 픽셀 너비 조정 */
  height: ${(props) => props.$pixelSize}px; /* 픽셀 높이 조정 */
  background-color: ${(props) => (props.$isBlack ? '#1A1A1A' : 'transparent')};
  transition: background-color 0.3s ease; /* 배경색 변화에 대한 애니메이션 추가 */
`;

// 픽셀 그리드 컴포넌트 정의
const PixelGrid = ({ width, height, pixelSize, top, bottom }) => {
  // 픽셀 색깔 상태를 관리하기 위한 useState 훅
  const [pixels, setPixels] = useState([]);

  // 컴포넌트가 처음 마운트될 때 실행되는 useEffect
  useEffect(() => {
    // 픽셀 변경을 위한 인터벌 설정
    const intervalIds = [];
    for (let i = 0; i < width * height; i++) {
      const initialProbability =
        (top && (height - Math.floor(i / width)) / height) || // top prop이 주어진 경우
        (bottom && (Math.floor(i / width) + 1) / height) || // bottom prop이 주어진 경우
        0.5; // 기본 값
      intervalIds.push(
        setInterval(() => {
          setPixels((prevPixels) => {
            const newPixels = [...prevPixels];
            const probability =
              initialProbability * (Math.random() * 0.5 + 0.5); // 초기 확률에 랜덤 팩터 추가
            newPixels[i] = Math.random() < probability; // 확률에 따라 검은색 픽셀 생성
            return newPixels;
          });
        }, Math.random() * 1000 + 500), // 500ms부터 1500ms 사이의 랜덤한 시간 간격으로 설정
      );
    }

    // 컴포넌트가 언마운트될 때 인터벌을 정리
    return () => {
      intervalIds.forEach(clearInterval);
    };
  }, [width, height, top, bottom]);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${width}, ${pixelSize}px)`,
        gridTemplateRows: `repeat(${height}, ${pixelSize}px)`,
      }}
    >
      {pixels.map((isBlack, index) => (
        <Pixel key={index} $isBlack={isBlack} $pixelSize={pixelSize} />
      ))}
    </div>
  );
};

const PixelLoader = ({ width, height, pixelSize, top, bottom }) => {
  return (
    <PixelGrid
      width={width}
      height={height}
      pixelSize={pixelSize}
      top={top}
      bottom={bottom}
    />
  );
};
export default PixelLoader;
