import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes} from "react-router-dom";
import {isAndroid, isIOS} from "react-device-detect";

import Video from "./Video/Video";
import Comment from "./Video/Comment";
import SingleComment from "./Video/SingleComment";

import {BottomLayerPopupWrapper} from "./Share.styles";
import { popupClose } from "store/layerPopupSlice";

const ShareContainer = () => {
    const dispatch = useDispatch();
    const layerPopup = useSelector(state => state.layerPopup);
    const [appReady, setAppReady] = useState(false);

    const checkedAppReady = () => {
        // Chrome Intent:
        // intent://path/#Intent;scheme=yourapp;package=io.feelky.feelky;

        // if(window.confirm('앱으로 실행하시겠습니까?')) {
            // window.location.href = 'market://signup'
            // window.location.href = 'instagram://user?username=feelky_official';
            // window.location.href = 'intent://user#Intent;scheme=instagram;package=com.instagram.android;end;';
        // } else setAppReady(false);
    }

    useEffect(() => {
        checkedAppReady()
    }, []);

    return (
        <>
            {
                appReady || (
                    <Routes>
                        <Route path={'/video'} element={<Video />} />
                        <Route path={'/comment'} element={<Comment />} />
                        <Route path={'/single-comment'} element={<SingleComment />} />
                    </Routes>
                )
            }
            {
                layerPopup?.show && <BottomLayerPopupWrapper onClick={() => dispatch(popupClose())}>
                    <div className={'layer-popup'} onClick={e => e.stopPropagation()}>
                        <div className={'title'}>{layerPopup?.title}</div>
                        <div className={'description'}>{layerPopup?.description}</div>
                        <div className={'button-wrap'}>
                            <button onClick={() => {
                                if(isAndroid) window.open('https://play.google.com/store/apps/details?id=io.feelky.feelky&hl=ko');
                                else if(isIOS) window.open('https://apps.apple.com/kr/app/%ED%95%84%ED%82%A4-%EC%A7%80%EA%B8%88-%EB%82%B4-%EC%A3%BC%EB%B3%80%EC%9D%98-%ED%96%89%EC%9A%B4%EC%9D%84-%EC%B0%BE%EB%8A%94-%EA%B3%B3/id6499114311');
                                else alert('모바일 기기로 접속해주세요');
                            }}>앱 설치하기</button>
                        </div>
                    </div>
                </BottomLayerPopupWrapper>
            }
        </>
    )
}

export default ShareContainer;