// import { Provider } from 'react-redux';
// import Router from './router';
// import store from './store';
import './index.css';

import Router from './router';

const App = () => {
  return (
    // <Provider store={store}>
    <Router />
    // </Provider>
  );
};

export default App;
