import styled from 'styled-components';
import BannerWrapper from '../../../../components/common/BannerWrapper/BannerWrapper';
import FeelkyFourthHumanImg from '../../../../assets/img/feelky-banner-fourth-human.png';
import FeelkyResponsiveFourthHumanImg from '../../../../assets/img/feelky-responsive-banner-fourth-human.png';
import Title from '../../../../components/common/Title/Title';

const BannerFourth = () => {
  const subBanners = ['내 주변', '청담/압구정', '홍대/신촌'];
  return (
    <BannerWrapper background="#262626">
      <StyledBannerFourthWrapper>
        <StyledBannerFourthTitleWrapper>
          <Title
            effectTitle="시간 지난 스토리가 지겨운 당신에게"
            mainTitle={
              <>
                <div> 지금 주위 소식과 이벤트를</div>
                <div>
                  <span>지금 찍은 영상으로!</span>
                </div>
              </>
            }
            subTitle={
              <>
                내 주변 새로운 사람들의 삶이 궁금할 때,
                <br />
                가보고 싶었던 그곳의 리얼 타임 현재 상황이 궁금할 때,
                <br />
                이미 찍어 놓은 영상이 아닌 지금 찍은 영상으로 확인하자!
              </>
            }
            mobileSubTitle={
              <>
                내 주변 새로운 사람들의 삶이 궁금할 때,
                <br />
                가보고 싶었던 그곳의 리얼 타임 현재 상황이 궁금할 때,
              </>
            }
          />
          <StyledBannerFourthSubBannerWrapper>
            {subBanners.map((value) => (
              <StyledBannerFourthSubBanner key={value}>
                {value}
              </StyledBannerFourthSubBanner>
            ))}
          </StyledBannerFourthSubBannerWrapper>
        </StyledBannerFourthTitleWrapper>
        <StyledBannerFourthImgWrapper>
          <StyledBannerFourthWebViewImg
            src={FeelkyFourthHumanImg}
            alt="FeelkyFourthHumanImg"
          />
        </StyledBannerFourthImgWrapper>
      </StyledBannerFourthWrapper>
    </BannerWrapper>
  );
};

export default BannerFourth;

const StyledBannerFourthWrapper = styled.div`
  width: 1090px;
  height: 100%;

  margin: 0 auto;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1090px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: stretch;
    gap: 40px;

    background-image: url(${FeelkyResponsiveFourthHumanImg});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 40%;
  }
`;

const StyledBannerFourthTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledBannerFourthImgWrapper = styled.div`
  width: 650px;
  aspect-ratio: 1 / 1;
  position: relative;

  > img {
    width: 100%;
  }

  @media (max-width: 1300px) {
    width: 50%;
  }

  @media (max-width: 800px) {
    width: 400px;
  }
  @media (max-width: 400px) {
    width: 80%;
    height: 50%;
    > img {
      height: 100%;
      object-fit: contain;
    }
  }
`;

const StyledBannerFourthWebViewImg = styled.img`
  position: absolute;
  top: 0;
  left: 27%;
  @media (max-width: 1300px) {
    width: 50%;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledBannerFourthSubBannerWrapper = styled.div`
  margin-top: 39px;

  display: flex;
  gap: 14px;

  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledBannerFourthSubBanner = styled.div`
  width: 90px;
  height: 90px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 14px;

  background: #353535;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  color: #94fecf;
`;
