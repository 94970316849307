import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    show: false,
    title: '',
    description: ''
}

const popupMessage = {
    MoveStore: {
        title: '내 주변 다른 영상들이 궁금한가요?',
        description: '필키 앱 설치하고, 지금 내 주변에 벌어지고 있는 재미있는 일들을 영상으로 만나보세요!'
    },
    DeleteVideo: {
        title: '해당 영상은 삭제된 영상이에요...',
        description: '아쉽지만 이 링크의 영상은 사용자에 의해 삭제되어 더 이상 시청하실 수 없어요. 앱 설치 후 내 주변 실시간 영상들을 시청해 보세요!'
    }
}

const layerPopupSlice = createSlice({
    name: 'layerPopup',
    initialState,
    reducers: {
        popupOpen: (state, action) => {
            Object.assign(state, popupMessage[action.payload])
            state.show = true
        },
        popupClose: state => {
            state.title = ''
            state.description = ''
            state.show = false
        }
    }
});

export const { popupOpen, popupClose } = layerPopupSlice.actions;
export default layerPopupSlice.reducer;
