import React, {useState} from 'react';
import {
    HeaderWrapper,
    HeaderLeft,
    HeaderRight
} from './HeaderRankingSystemView.styles';

import iconRefresh from 'assets/icon/ranking-refresh.svg';

const HeaderRankingSystemView = ({ handleRefresh, eventTimeRemaining }) => {
    let [isActive, setIsActive] = useState(false);

    return (
        <HeaderWrapper>
            <HeaderLeft>
                <span>실시간 투표 결과</span>
                <img src={iconRefresh} alt="refresh" className={isActive ? 'active':''} onClick={() => {
                    setIsActive(false);
                    handleRefresh();

                    setTimeout(() => { setIsActive(true); }, 250)
                }}/>
            </HeaderLeft>
            <HeaderRight>
                종료까지<span>{eventTimeRemaining}</span>
            </HeaderRight>
        </HeaderWrapper>
    );
};

export default HeaderRankingSystemView;
