import styled from "styled-components";

import badgeLocation from "assets/icon/share_video_badge_location.svg";
import iconTime from "assets/icon/share_video_time.svg";

const VideoBottomContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 64%);

    > div {
        margin-top: 8px;
    }

    > .badge-wrap {
        display: flex;

        > span {
            display: flex;
            align-items: center;

            padding: 6px 10px;
            border: 1px solid RGBA(145, 158, 171, 0.3);
            border-radius: 16px;
            background: RGBA(0, 0, 0, 0.5);

            color: #ffffff;
            font-size: 12px;
            font-weight: 300;

            &.location {
                color: #94fecf;

                &:before {
                    content: url(${badgeLocation});
                    height: 12px;
                    margin-right: 6px;
                }
            }

            &:not(:first-child) {
                margin-left: 6px;
            }
        }
    }

    > .title-wrap {
        padding: 0;
        color: #ffffff;
        font-size: 15px;
        font-weight: 400;
    }

    > .tag-wrap {
        display: flex;
        justify-content: flex-start;

        color: #ffffff;
        font-size: 12px;
        font-weight: 250;

        & > .tags {
            width: 60%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.active {
                width: auto;
                white-space: break-spaces;
            }
        }

        & > .more {
            font-weight: 400;
        }
    }

    > .additional-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .nickname {
            color: #ffffff;
            font-size: 12px;
            font-weight: 300;

            &:before {
                content: '@';
            }
        }

        > .time {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            padding: 6px;

            border-radius: 16px;
            background: RGBA(0, 0, 0, 0.4);

            color: #ffffff;
            font-size: 11px;
            font-weight: 300;

            &:before {
                content: url(${iconTime});
                width: 12px;
                height: 12px;

                margin-right: 6px;
            }

            &.extra:after {
                content: '${props => props.$extraTime && '(+' + props.$extraTime + ')'}';
                margin-left: 4px;
                color: #94fecf;
            }

            &.ending {
                background: #F76659;

                &.extra:after {
                    color: #ffffff;
                }
            }
        }
    }
`;

export { VideoBottomContainer };