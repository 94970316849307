/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';

const CustomBackground = ({ children }) => {
  const effectComponents = [];
  const otherComponents = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type === Effect) {
      effectComponents.push(child);
    } else {
      otherComponents.push(child);
    }
  });

  return (
    <CustomBackgroundWrapper>
      <CustomEffectWrapper>
        {effectComponents.map((component, index) => (
          <Overlay key={index}>{component}</Overlay>
        ))}
      </CustomEffectWrapper>
      {otherComponents}
    </CustomBackgroundWrapper>
  );
};

const Effect = ({ children }) => {
  return children;
};

export default CustomBackground;
export { Effect };

const CustomBackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CustomEffectWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
