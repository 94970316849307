import {MainVideoBoxWrapper} from "./MainVideoBox.styles";
import {useSearchParams} from "react-router-dom";


const MainVideoBox = video => {
    const [searchParams] = useSearchParams();

    return <MainVideoBoxWrapper onClick={() => {
        window.location.href = `/app/share/video?st=${searchParams.get('st')}&type=400101&id=${video.videoId}`
    }}>
        <img src={video?.thumbnail} className={'thumbnail'} alt={'thumbnail'}/>
        <div className={'info'}>
            <div className={'title'}>{video?.title}</div>
            <div className={'nickname'}>@{video?.nickname}</div>
        </div>
    </MainVideoBoxWrapper>
}

export default MainVideoBox;