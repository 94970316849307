import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Portal from '../../../../components/common/Portal/Portal';
import FeelkySplachRader from '../../../../assets/img/feelky-splash-popup-rader.png';

const messages = [
  '망설이지 말고 일단 시도해보세요!',
  '뜻밖에 만남이 기다리고 있어요.',
  '기회를 잡으세요.',
  '주변이 시끄러울 수 있어요.',
  '새로운 이벤트를 즐겨보세요.',
  '솔직하게 표현하면 많은 것이 해결돼요.',
  '예상치 못한 금전운이 있네요.',
  '과거의 사람에게 연락이 올거에요.',
  '먼저 말을 걸어 보세요.',
  '주목받을 일이 생겨요.',
  '새로운 시작이 당신을 기다리고 있어요.',
];

function getSeenMessages() {
  const seen = localStorage.getItem('seenMessages');
  return seen ? JSON.parse(seen) : [];
}

function saveSeenMessage(message) {
  const seen = getSeenMessages();
  seen.push(message);
  localStorage.setItem('seenMessages', JSON.stringify(seen));
}

function getRandomMessage() {
  const seenMessages = getSeenMessages();
  const unseenMessages = messages.filter(
    (message) => !seenMessages.includes(message),
  );

  if (unseenMessages.length === 0) {
    return false;
  }

  const randomIndex = Math.floor(Math.random() * unseenMessages.length);
  const randomMessage = unseenMessages[randomIndex];

  saveSeenMessage(randomMessage);

  return randomMessage;
}

const SplashPopup = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [luckyMessage, setLuckyMessage] = useState('');

  useEffect(() => {
    const message = getRandomMessage();
    setLuckyMessage(message);
  }, []);

  return (
    <StyledPortalWrapper $isOpen={isOpen}>
      {isOpen && luckyMessage && (
        <Portal onClose={() => {}}>
          <SplashPopupWrapper>
            <SplachPopupRaderWrapper>
              <img src={FeelkySplachRader} alt="FeelkySplachRader" />
              <div>Feeling Lucky</div>
            </SplachPopupRaderWrapper>
            <SplachPopupLuckyMessage>
              <span>[ 오늘의 행운 ]</span>
              <div>{luckyMessage}</div>
            </SplachPopupLuckyMessage>
            <SplachPopupCloseButton onClick={() => setIsOpen(false)}>
              필키 알아보기
            </SplachPopupCloseButton>
          </SplashPopupWrapper>
        </Portal>
      )}
    </StyledPortalWrapper>
  );
};

export default SplashPopup;

const StyledPortalWrapper = styled.div`
  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      display: none;
    `}
`;

const SplashPopupWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
`;

const SplachPopupRaderWrapper = styled.div`
  width: 100%;
  height: 254px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    height: 100%;
  }

  > div {
    width: 100%;
    position: absolute;
    bottom: 0;
    white-space: nowrap;

    font-family: Pretendard;
    font-size: 40px;
    font-weight: 900;
    letter-spacing: 0.04em;
    text-align: center;
    color: #ffffff;
  }
  @media (max-width: 800px) {
    height: 223px;

    > div {
      font-size: 34px;
      letter-spacing: 0;
    }
  }
`;

const SplachPopupLuckyMessage = styled.div`
  width: 100%;
  margin-top: 11px;

  font-family: Pretendard;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;

const SplachPopupCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 49px;

  width: 200px;
  height: 44px;
  padding: 14px 30px 14px 30px;
  border-radius: 8px;
  opacity: 0px;

  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #000000;

  cursor: pointer;
  background: linear-gradient(90deg, #bcffe0 -20.97%, #c6c4ff 114.58%);

  @media (max-width: 800px) {
    width: 147px;
    height: 44px;
    margin-top: 26px;
  }
`;
