import { CommentHeaderWarpper, EndTimeMessageWrapper } from './CommentHeader.styles';
import {useEffect, useState} from "react";
import moment from "moment/moment";
import {useNavigate, useSearchParams} from "react-router-dom";

const CommentHeader = props => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const onClickBack = () => {
        window.location.href = `/app/share/video?st=${searchParams.get('st')}&type=400101&id=${props?.videoId}`
        // navigate(-1);
    }

    const onClickTimeLeft = e => {
        let timeDiff = moment(props.videoEndTime).diff(moment().format('YYYY-MM-DD HH:mm:ss'));
        let timeConvert = moment.duration(timeDiff, 'milliseconds').asHours();

        let _hour = Math.floor(timeConvert);
        let _minute = Math.floor((timeConvert - _hour) * 60);

        let _message= `메인영상 종료까지 ${0 < _hour ? _hour+'시간 ':''}${0 < _minute ? _minute+'분 ':''}남았어요!`;
        props?.setMessagePopup({ show:true, message:_message});
        e.stopPropagation()
    }

    const onClickGallery = () => {
        props.setGalleryShow(!props.galleryShow)
    }

    return <CommentHeaderWarpper>
        <span className={'icon back'} onClick={onClickBack}></span>
        <span className={'title'}>{ props?.title }</span>
        <span className={`icon gallery ${props?.galleryShow && 'off'}`} onClick={onClickGallery}></span>
        <span className={'icon time-left'} onClick={e => { onClickTimeLeft(e) }}>
            { props?.messagePopup.show && <span className={'message-popup'}>{props?.messagePopup.message}</span> }
        </span>
    </CommentHeaderWarpper>
}

export default CommentHeader;