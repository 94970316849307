import {useDispatch} from "react-redux";
import {popupOpen} from "store/layerPopupSlice";
import {VideoMiddleContainer, VideoMiddleIconWrapper} from "./VideoMiddle.styles";

import iconComment from "assets/icon/share_video_comment.svg";
import iconFeelkyRequest from "assets/icon/share_video_feelky_request_off.svg";
import iconLike from "assets/icon/share_video_like_off.svg";
import iconShare from "assets/icon/share_video_share_on.svg";
import iconMore from "assets/icon/share_video_more.svg";
import {useSearchParams} from "react-router-dom";

const VideoMiddle = props => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const onClickComment = () => {
        window.location.href = `/app/share/comment?st=${searchParams.get('st')}&id=${props?.videoId}`
    }

    const onClickMoveStore = () => {
        dispatch(popupOpen('MoveStore'))
    }

    return <VideoMiddleContainer>
        <VideoMiddleIconWrapper>
            {
                props.videoType === '400101' && <div className={'icon-wrap comment'} onClick={ onClickComment }>
                    <div className={'icon'}><img width="26" src={iconComment} alt={'comment'}/></div>
                    <div className={'count'}>{props.commentCount || ''}</div>
                </div>
            }
            <div className={'icon-wrap like'} onClick={onClickMoveStore}>
                <div className={'icon'}><img width="26" src={iconLike} alt={'like'}/></div>
                <div className={'count'}>{props.likeCount || ''}</div>
            </div>
            <div className={'icon-wrap request'} onClick={onClickMoveStore}>
                <div className={'icon'}><img width="22" src={iconFeelkyRequest} alt={'request'}/></div>
                <div className={'count'}></div>
            </div>
            <div className={'icon-wrap share'} onClick={onClickMoveStore}>
                <div className={'icon'}><img width="26" src={iconShare} alt={'share'}/></div>
                <div className={'count'}></div>
            </div>
            <div className={'icon-wrap more'} onClick={onClickMoveStore}>
                <div className={'icon'}><img width="18" src={iconMore} alt={'more'}/></div>
                <div className={'count'}></div>
            </div>
        </VideoMiddleIconWrapper>
    </VideoMiddleContainer>
}


export default VideoMiddle;