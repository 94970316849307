/* eslint-disable react/no-array-index-key */
import styled, { css, keyframes } from 'styled-components';
import useInView from '../../../hooks/useInView';

const Title = ({
  effectColor = 'default',
  fontColor = 'default',
  subFontColor = 'default',
  effectTitle,
  mainTitle,
  mobileMainTitle = mainTitle,
  subTitle,
  mobileSubTitle = subTitle,
}) => {
  const [isInView, ref] = useInView();

  return (
    <StyledBannerTitleWrapper ref={ref} $fontColor={fontColor}>
      <StyledBannerEffectTitle>
        <AppearDiv>
          <AnimatedContent className={isInView ? 'active' : ''}>
            <StyledBannerEffectFont $effectColor={effectColor}>
              {effectTitle}
            </StyledBannerEffectFont>
          </AnimatedContent>
        </AppearDiv>
      </StyledBannerEffectTitle>

      <StyledBannerMainTitle>
        <StyledIsWeb>
          {mainTitle?.props.children.map((title, index) => (
            <AppearDiv key={index}>
              <AnimatedContent
                className={isInView ? 'active' : ''}
                delay={(index + 1) * 0.2}
              >
                {title}
              </AnimatedContent>
            </AppearDiv>
          ))}
        </StyledIsWeb>
        <StyledIsMobile>
          {mobileMainTitle?.props.children.map((title, index) => (
            <AppearDiv key={index}>
              <AnimatedContent
                className={isInView ? 'active' : ''}
                delay={(index + 1) * 0.2}
              >
                {title}
              </AnimatedContent>
            </AppearDiv>
          ))}
        </StyledIsMobile>
      </StyledBannerMainTitle>

      <StyledBannerSubTitle $subFontColor={subFontColor}>
        <AppearDiv>
          <AnimatedContent className={isInView ? 'active' : ''} delay={0.6}>
            <StyledIsWeb>{subTitle}</StyledIsWeb>
            <StyledIsMobile>{mobileSubTitle}</StyledIsMobile>
          </AnimatedContent>
        </AppearDiv>
      </StyledBannerSubTitle>
    </StyledBannerTitleWrapper>
  );
};

export default Title;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const AppearDiv = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const AnimatedContent = styled.div`
  width: 100%;
  height: 100%;

  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  transform: translateY(100%);

  &.active {
    opacity: 1;
    animation: ${slideUp} 0.5s ease-out forwards;
    animation-delay: ${(props) => props.delay || 0}s;
  }
`;

const effectTitleColor = {
  default: css`
    background: linear-gradient(90deg, #bcffe0 -20.97%, #c6c4ff 114.58%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  purple: css`
    color: #645dd1;
  `,
};

const fontColor = {
  default: css`
    color: #ffffff;
  `,
  black: css`
    color: #000000;
  `,
};

const subFontColor = {
  default: css`
    color: #c4c4c4;
  `,
  black: css`
    color: #262626;
  `,
};

const StyledBannerTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ $fontColor }) => fontColor[$fontColor]};
`;

const StyledBannerEffectTitle = styled.div`
  font-size: 24px;
  font-weight: 200;

  @media (max-width: 1600px) {
    font-size: 20px;
  }
  @media (max-width: 800px) {
    font-size: 17px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

const StyledBannerEffectFont = styled.div`
  ${({ $effectColor }) => effectTitleColor[$effectColor]}
`;

const StyledBannerMainTitle = styled.div`
  margin-top: 30px;

  font-size: 38px;
  font-weight: 300;

  span {
    font-weight: 500;
  }

  @media (max-width: 1600px) {
    font-size: 34px;
    margin-top: 22px;
  }
  @media (max-width: 800px) {
    font-size: 29px;
    margin-top: 16px;
  }
  @media (max-width: 400px) {
    /* font-size: 24px; */
    margin-top: 10px;
  }
`;

const StyledIsWeb = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledIsMobile = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;

const StyledBannerSubTitle = styled.div`
  ${({ $subFontColor }) => subFontColor[$subFontColor]};

  margin-top: 25px;

  font-size: 18px;
  font-weight: 200;
  line-height: 22px;

  @media (max-width: 1600px) {
    font-size: 16px;
    margin-top: 20px;
  }
  @media (max-width: 800px) {
    font-size: 14px;
    margin-top: 15px;
  }

  @media (max-width: 400px) {
    /* font-size: 12px; */
    margin-top: 10px;
  }
`;
